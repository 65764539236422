import {FC, useEffect, useRef, useState} from 'react';
import {Sprite} from "@pixi/react";
import useCalculateSize from "../../../hooks/useCalculateSize";
import useWindowSize from "../../../hooks/useWindowSize";

import all_bikes from "../../../assets/img/animation/all_bikes.png";
import phenix from "../../../assets/img/animation/phenix.png";
import cloud_1 from "../../../assets/img/animation/cloud_1.png";
import cloud_2 from "../../../assets/img/animation/cloud_2.png";
import {gsap} from "gsap";

import {PixiPlugin} from "gsap/dist/PixiPlugin";
import * as PIXI from "pixi.js";
import useCroppedTextures from "../../../hooks/useCroppedTextures";
import {animationFinish, animationStart} from "../../../helpers/animations";


gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);
interface CropRectangle {
    x: number;
    y: number;
    width: number;
    height: number;
}

interface IAnimationsItems{
    firstEmail?:boolean;
    mapLoading?: boolean;
    loadedApp?: boolean;
}

const cropRectangles: CropRectangle[] = [
    { x: 0, y: 0, width: 110, height: 160 },
    { x: 0, y: 157, width: 110, height: 160 },

    { x: 104, y: 0, width: 110, height: 160 },
    { x: 104, y: 157, width: 110, height: 160 },

    { x: 215, y: 0, width: 110, height: 160 },
    { x: 215, y: 157, width: 110, height: 160 },

    { x: 322, y: 0, width: 105, height: 160 },
    { x: 322, y: 157, width: 105, height: 160 },
];
const AnimationsItems:FC<IAnimationsItems> = ({firstEmail,mapLoading=false,loadedApp=false}) => {

    const calculateSize = useCalculateSize();
    const {width,height} = useWindowSize();

    const cloud1Ref = useRef<any>();
    const cloud2Ref = useRef<any>();
    const cloud3Ref = useRef<any>();
    const phenixRef = useRef<any>();
    const phenixRef2 = useRef<any>();

    const bicycle1 = useRef<any>();
    const bicycle2 = useRef<any>();
    const bicycle3 = useRef<any>();
    const bicycle1Texture = useRef<any>();
    const bicycle2Texture = useRef<any>();
    const bicycle3Texture = useRef<any>();



    const isLoaded = useRef<any>();
    const [loadedBikes,setLoadedBikes] = useState(false);

    const currentAlpha = useRef<number>(0);
    const bicycles = useCroppedTextures(all_bikes, cropRectangles);

    useEffect(() => {
        const timelineEx = gsap.timeline();
        let timeoutId: NodeJS.Timeout | null = null;
        const needEmail = localStorage.getItem('burningEmail') ? localStorage.getItem('burningEmail') : firstEmail;
        if( mapLoading && loadedApp && !isLoaded.current && needEmail){
            timeoutId=setTimeout(() => {

                //  myMatrix.translate(1000, 500);
                const timeline = gsap.timeline();


                timeline.fromTo(bicycle1.current, {
                    pixi: {
                        alpha:0,
                    },
            }, {
                    pixi: {
                        alpha:1,
                    },
            }, 0.3);
                timeline.fromTo(bicycle2.current, {
                    pixi: {
                        alpha:0,
                    },
                }, {
                    pixi: {
                        alpha:1,
                    },
                }, 0.3);
                timeline.fromTo(bicycle3.current, {
                    pixi: {
                        alpha:0,
                    },
                }, {
                    pixi: {
                        alpha:1,
                    },
                }, 0.3);


                setLoadedBikes(true)
                currentAlpha.current = 1;
                isLoaded.current = true
            },2000)
        }
        return () => {


            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [mapLoading,width,height,loadedApp,firstEmail]);

    useEffect(() => {

        if( mapLoading && loadedApp && isLoaded.current && loadedBikes) {
            const timelineEx = gsap.timeline();
            timelineEx.fromTo(cloud1Ref.current, {
                pixi: {
                    alpha:0.62,
                    x: calculateSize(-1253),
                    y: calculateSize(2427)
                },
                duration: 60,
            },{
                pixi: {
                    alpha:0.62,
                    x: calculateSize(4053),
                    y: calculateSize(-1000)
                },
                duration: 40,
                repeat: -1,
                ease: "linear"
            },0.3);
            timelineEx.fromTo(cloud2Ref.current,{
                pixi: {
                    alpha:0.62,
                    x: calculateSize(-903),
                    y: calculateSize(807)
                },

            },{
                pixi: {
                    alpha:0.62,
                    x: calculateSize(2053),
                    y: calculateSize(-1000)
                },
                delay: 20,
                duration: 30,
                repeat: -1,
                ease: "linear",
            },0.3);
            timelineEx.fromTo(cloud3Ref.current, {
                pixi: {
                    alpha:0.62,
                    x: calculateSize(803),
                    y: calculateSize(3227)
                },
            },{
                pixi: {
                    alpha:0.62,
                    x: calculateSize(6053),
                    y: calculateSize(-1000)
                },
                delay: 15,
                duration: 40,
                repeat: -1,
                ease: "linear"
            },0.3);

            const timeDev = 3;

            timelineEx.fromTo(bicycle1.current, {
                pixi: {
                    alpha: 1,
                    x: calculateSize(5803),
                    y: calculateSize(1237)
                },
            }, {
                pixi: {
                    alpha: 1,
                    x: calculateSize(3883),
                    y: calculateSize(197)
                },
                duration: 14, // Каждую секунду
                repeat: -1,
                yoyo: true,
                onUpdate: function () {
                    if (this.progress() >= 0.999 ) {
                        // Когда анимация достигает конца и не в обратном направлении
                        bicycle1.current.texture = bicycles[0];
                    } else if (this.progress() <= 0.010) {
                        // Когда анимация возвращается к началу в обратном направлении
                        bicycle1.current.texture = bicycles[1];
                    }
                },
           /*     onRepeat: () => {
                    // Логика для переключения текстуры при каждом повторении

                    let currentTexture = bicycle1Texture.current;
                    bicycle1.current.texture = bicycles[0]// currentTexture === bicycles[0] ? bicycles[1] : bicycles[0];
                    bicycle1Texture.current = bicycle1.current.texture
                },*/
                ease: "linear"
            }, 0.1);


            timelineEx.fromTo(bicycle2.current, {
                pixi: {
                    alpha: 1,
                    x: calculateSize(5803),
                    y: calculateSize(1237)
                },
            }, {
                pixi: {
                    alpha: 1,
                    x: calculateSize(3883),
                    y: calculateSize(197)
                },
                duration: 15,
                repeat: -1,
                yoyo: true,
                onUpdate: function () {
                    if (this.progress() >= 0.999 ) {
                        // Когда анимация достигает конца и не в обратном направлении
                        bicycle2.current.texture = bicycles[2];
                    } else if (this.progress() <= 0.010) {
                        // Когда анимация возвращается к началу в обратном направлении
                        bicycle2.current.texture = bicycles[3];
                    }
                },
             /*   onStart: () => {
                    bicycle2.current.texture = bicycles[3];
                },
                onRepeat: () => {

                    let currentTexture = bicycle2Texture.current;
                    bicycle2.current.texture = bicycles[2] //currentTexture === bicycles[2] ? bicycles[3] : bicycles[2];
                    bicycle2Texture.current = bicycle2.current.texture
                },*/

                delay: 1.5,
                ease: "linear"
            }, 0.1);
/*            timelineEx.fromTo(bicycle3.current, {
                pixi: {
                    alpha: 1,
                    x: calculateSize(5803),
                    y: calculateSize(1237)
                },
            }, {
                pixi: {
                    alpha: 1,
                    x: calculateSize(3883),
                    y: calculateSize(197)
                },
                duration: 16,
                repeat: -1,
                yoyo: true,
                onStart:()=>{

                },
                onRepeat: () => {

                    let currentTexture = bicycle3Texture.current;
                    bicycle3.current.texture = currentTexture === bicycles[6] ? bicycles[5] : bicycles[6];
                    bicycle3Texture.current = bicycle3.current.texture
                },
                delay: 3,
                ease: "linear"
            }, 0.1);*/

            let bicycle3Time = true;
            timelineEx.fromTo(bicycle3.current, {
                pixi: {
                    alpha: 1,
                    x: calculateSize(5803),
                    y: calculateSize(1237)
                },
            }, {
                pixi: {
                    alpha: 1,
                    x: calculateSize(3883),
                    y: calculateSize(197)
                },
                duration: 16,
                repeat: -1,
                yoyo: true,

                onUpdate: function () {
                   if (this.progress() >= 0.999 ) {
                        // Когда анимация достигает конца и не в обратном направлении
                        bicycle3.current.texture = bicycles[6];
                    } else if (this.progress() <= 0.010) {
                        // Когда анимация возвращается к началу в обратном направлении
                        bicycle3.current.texture = bicycles[5];
                    }
                },

                delay: 3,
                ease: "linear"
            }, 0.1);


            timelineEx.fromTo(phenixRef.current, {
                pixi: {
                    alpha:1,
                    x: calculateSize(6083),
                    y: calculateSize(1817)
                },
            },{
                pixi: {
                    alpha:1,
                    x: calculateSize(0),
                    y: calculateSize(-1000)
                },
                delay: 0,
                duration: 20,
                repeatDelay: 35,
                repeat: -1,
                ease: "linear"
            },0.3);

            timelineEx.fromTo(phenixRef2.current, {
                pixi: {
                    alpha:1,
                    scale:0.4,
                    x: calculateSize(4083),
                    y: calculateSize(3517)
                },
            },{
                pixi: {
                    alpha:1,
                    scale:0.4,
                    x: calculateSize(-5000),
                    y: calculateSize(-2200)
                },
                delay: 25,
                duration: 30,
                repeat: -1,
                repeatDelay: 25,
                ease: "linear"
            },0.3);




            return () => {
                if (timelineEx) {

                    timelineEx.kill()
                }
            }
        }
    }, [loadedBikes,width,height]);
    function visibleAll(){

        const alphaAnimate = {
            pixi:{
                alpha:1
            }
        }
        const allRefs = [phenixRef,

        ];
        const timelineEx = gsap.timeline();
        allRefs.forEach((ref,index) => {
            if (ref.current) {
                timelineEx.set(ref.current, alphaAnimate);
            }
        });
    }

/*    useEffect(() => {
        if( mapLoading && loadedApp && isLoaded.current) {
            visibleAll();
        }
    }, [mapLoading,loadedApp,width,height]);*/

    const defaultAlpha = (currentAlpha.current) ? currentAlpha.current :  0;

    //all_bikes
    return (
        <>
            <Sprite
                ref={cloud1Ref}
                alpha={0}
                x={calculateSize(53)}//calculateSize(2263)
                y={calculateSize(1227)}//calculateSize(767)
                width={calculateSize(1211)}
                height={calculateSize(714)}
                image={cloud_1}
                zIndex={4}
            />
            <Sprite
                ref={cloud2Ref}
                alpha={0}
                x={calculateSize(103)}//calculateSize(2263)
                y={calculateSize(307)}//calculateSize(767)
                width={calculateSize(873)}
                height={calculateSize(520)}
                image={cloud_2}
                zIndex={4}
            />
            <Sprite
                ref={cloud3Ref}
                alpha={0}
                x={calculateSize(2103)}//calculateSize(2263)
                y={calculateSize(2027)}//calculateSize(767)
                width={calculateSize(1442)}
                height={calculateSize(821)}
                image={cloud_2}
                zIndex={4}
            />



            <Sprite
                ref={phenixRef}
                alpha={0}
                x={calculateSize(6083)}
                y={calculateSize(1817)}
                width={calculateSize(704)}
                height={calculateSize(467)}
                image={phenix}
                zIndex={3}
            />

            <Sprite
                ref={phenixRef2}
                alpha={0}
                x={calculateSize(3083)}
                y={calculateSize(4817)}
                width={calculateSize(704)}
                height={calculateSize(467)}
                image={phenix}
                zIndex={3}
            />

            <Sprite
                ref={bicycle1}
                alpha={defaultAlpha}
                x={calculateSize(5803)}
                y={calculateSize(1237)}
                width={calculateSize(110)}
                height={calculateSize(160)}
                texture={bicycle1Texture.current ? bicycle1Texture.current : bicycles[1]}
                zIndex={-1}
            />
            <Sprite
                ref={bicycle2}
                alpha={defaultAlpha}
                x={calculateSize(5803)}
                y={calculateSize(1237)}
                width={calculateSize(110)}
                height={calculateSize(160)}
                texture={bicycle2Texture.current ? bicycle2Texture.current : bicycles[3]}
               //texture={bicycles[3]}
                zIndex={-2}
            />
            <Sprite
                ref={bicycle3}
                alpha={defaultAlpha}
                x={calculateSize(5803)}
                y={calculateSize(1237)}
                width={calculateSize(110)}
                height={calculateSize(160)}
                texture={bicycle3Texture.current ? bicycle3Texture.current : bicycles[5]}
              //  texture={bicycles[5]}
                zIndex={-3}
            />
        </>
    );
};

export default AnimationsItems;