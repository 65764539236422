import {combineReducers,configureStore} from "@reduxjs/toolkit";
import appSlice from "./slices/appSlice";
import checkSlice from "./slices/checkSlice/checkSlice";
import modalSlice from "./slices/modalSlice/modalSlice";

const rootReducer = combineReducers({
    app: appSlice,
    modals: modalSlice,
    checks: checkSlice,

});

export const store = configureStore({
    reducer: rootReducer
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch