import instance from "./api";
import {AxiosResponse} from 'axios';


export default class MailService {
    static async sendGetInfo({email,clan,sub_clan,accommodations,meal,mobility,travel,attendType}:any): Promise<AxiosResponse<any>> {
        return instance.post<any>('participants/form/getInfo', {
            email,
            clan,
            sub_clan,
            accommodations,
            meal,
            mobility,
            travel,
            attendType
        })
    }
    static async sendSetEmail({name,email,attendType,person}:any): Promise<AxiosResponse<any>> {
        return instance.post<any>('participants/form/setEmail', {
            name,
            email,
            attendType,
            person
        })
    }
}