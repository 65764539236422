import {FC, useEffect, useRef} from 'react';
import {Sprite} from "@pixi/react";
import night from "../../../assets/img/night.png";
import light_1 from "../../../assets/img/light_1.png";
import light_2 from "../../../assets/img/light_2.png";
import useCalculateSize from "../../../hooks/useCalculateSize";
import useWindowSize from "../../../hooks/useWindowSize";
import {gsap} from "gsap";
import FireAnimation from '../FireAnimation/FireAnimation';


interface IAnimationsItems{
    currentKey?:any;
    dispatch?:any;
    mapLoading?: boolean;
    loadedApp?: boolean;
}

const NightMode:FC<IAnimationsItems> = ({currentKey,dispatch=()=>{},mapLoading=false,loadedApp=false}) => {
    const calculateSize = useCalculateSize();
    const {width,height} = useWindowSize();

    const lightRef1 = useRef<any>();
    const lightRef2 = useRef<any>();

    const nightRef1 = useRef<any>();

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;

        if( mapLoading && loadedApp && currentKey === 3){
            timeoutId=setTimeout(() => {

                //  myMatrix.translate(1000, 500); nightRef1
                const timeline = gsap.timeline();
                timeline.fromTo(nightRef1.current, {
                    pixi: {
                        alpha: 0,
                    },
                },{
                    pixi: {
                        alpha: 1,
                    },
                    duration:1.5,
                    ease: "power2.in",
                },0.3);
                timeline.fromTo(lightRef1.current, {
                    pixi: {
                        alpha: 0,
                    },
                    duration:1.5
            },{
                    pixi: {
                        alpha: 1,
                    },
                    duration:1.5,
                    yoyo:true,
                    ease: "power2.in",
                    repeat: -1
                },1.9);
                timeline.fromTo(lightRef2.current, {
                    pixi: {
                        alpha: 0.4,
                    },
                    duration:1.5
                },{
                    pixi: {
                        alpha: 1,
                    },
                    duration:1.5,
                    yoyo:true,
                    ease: "power2.in",
                    repeat: -1,
                    delay: 1
                },1.9);
            },1000)
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [mapLoading,width,height,loadedApp,currentKey]);
    return (
        <>

            <Sprite
                ref={nightRef1}
                alpha={0}
                zIndex={100}
                width={calculateSize(5760)}
                height={calculateSize(3240)}
                image={night}
            />
            <Sprite
                alpha={0}
                ref={lightRef1}
                zIndex={101}
                width={calculateSize(3596)}
                height={calculateSize(2242)}
                x={calculateSize(813)}
                y={calculateSize(347)}
                image={light_1}
            />
            <Sprite
                alpha={0}
                ref={lightRef2}
                zIndex={102}
                width={calculateSize(3596)}
                height={calculateSize(2242)}
                x={calculateSize(813)}
                y={calculateSize(347)}
                image={light_2}
            />

        </>
    );
};

export default NightMode;