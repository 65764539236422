import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { createSound } from "../../helpers/scripts";
import {asyncSendFormAction} from "./appAction";

import soundKey from '../../assets/audio/key.mp3'

interface appState {
    loadedApp: boolean;
    modalSuccess:boolean;
    modalErrorMessage:boolean;
    modalVideo: boolean;
    modalIntro:boolean;
    modalIntroBack: boolean;
    isTappedTitle:boolean;

    currentKeys:number;
    currentKeysCounter: number;
    disabledCanvasHover:boolean;
    isOpenKeyModal:boolean;
    isCloseSecondModal:boolean;

    firstEmail:boolean;

    isLoading: boolean;
    mapLoading: boolean;
    preloaderPercent:number;
    preloader:boolean;
    error: string;
    errorCode: string;
    successMessage: string;
    successMessageError: string;
}

const initialState: appState = {
    isCloseSecondModal: false,
    modalSuccess: false,
    modalErrorMessage:false,
    modalVideo: false,
    modalIntro: false,
    modalIntroBack: false,
    isTappedTitle:false,
    disabledCanvasHover: true,
    isOpenKeyModal:false,
    firstEmail: false,
    currentKeys: 0,
    currentKeysCounter: 0,
    mapLoading:false,
    loadedApp: false,
    preloaderPercent: 0,
    preloader:true,
    isLoading: false,
    error: '',
    errorCode: '',
    successMessage: '',
    successMessageError: ""
};


const appSlice = createSlice({
    name:'appSlice',
    initialState,
    reducers:{//
        changeVideoModal(state,action){
            state.modalVideo = action.payload;
        },
        changeIsCloseSecondModal(state,action){
            state.isCloseSecondModal = action.payload;
        },

        changeIsOpenKeyModal(state,action){
            state.isOpenKeyModal = action.payload;
        },
        changeFirstEmail(state,action){
            state.firstEmail = action.payload;
        },
        changeIsTappedTitle(state,action){
            state.isTappedTitle = action.payload;
        },
        changeDisabledCanvasHover(state,action){
            state.disabledCanvasHover = action.payload;
        },
        changeCurrentKeys(state,action){
            state.currentKeys = action.payload;
         /*   if(action.payload != 0){
                createSound(soundKey);
            }*/

        },
        changeCurrentKeysCounter(state,action){
            state.currentKeysCounter = action.payload;
        },
        changeModalIntroBack(state,action){
            state.modalIntroBack = action.payload;
        },
        changeModalIntro(state,action){
            state.modalIntro = action.payload;
        },
        changePreloaderPercent(state,action){
            state.preloaderPercent = action.payload;
        },
        changeMapLoading(state,action){
            state.mapLoading = action.payload;
        },
        changeLoadedApp(state,action){
            state.loadedApp = action.payload;
        },
        changeModalSuccess(state,action){
            state.modalSuccess = action.payload;
        },
        changeModalErrorMessage(state,action){
            state.modalErrorMessage = action.payload;
        },
        changePreloader(state,action){
            state.preloader = action.payload;
        }
    },
    extraReducers: (builder:any) => {

    },
});
export const {
    changeVideoModal,
    changeIsCloseSecondModal,
    changeDisabledCanvasHover,
    changeIsOpenKeyModal,
    changeFirstEmail,
    changeIsTappedTitle,
    changeCurrentKeys,
    changeCurrentKeysCounter,
    changeModalIntroBack,
    changeModalIntro,
    changeMapLoading,
    changeLoadedApp,
    changePreloaderPercent} = appSlice.actions;
export default appSlice.reducer;