export const animationStart = (sprite:any,duration=1) => {
    return {
        pixi: {
            alpha: 0,
            y: sprite.y+50
        },
        duration,
    }
}
export const animationFinish = (sprite:any,duration=1) => {

    return {
        pixi: {
            alpha: 1,
            y: sprite.y
        },
        duration,
        ease: "power2.out"
    }
}

