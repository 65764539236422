import {FC} from 'react';
import Modal from "../Modal/Modal";
import ModalInfo from "../ModalContent/ModalInfo/ModalInfo";
import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";
import {changeModalPrinciples, changeModalSecondKey} from "../../../redux/slices/modalSlice/modalSlice";
import {changeCheckInfo} from "../../../redux/slices/checkSlice/checkSlice";
import Button from '../../atoms/Button/Button';
import {changeCurrentKeys, changeDisabledCanvasHover} from "../../../redux/slices/appSlice";

const ModalPrinciples:FC = () => {
    const {modalPrinciples} = useSelectorEx(state=>state.modals);
    const {isCloseSecondModal} = useSelectorEx(state=>state.app);
    const dispatch = useDispatchEx();



    const onCloseEx = ()=>{
        const checkInfo = localStorage.getItem('checkInfo');
        const oldCheck = checkInfo?.split(',');

        if(!oldCheck || !oldCheck.includes("scroll")){
            const newValue = checkInfo ? [...checkInfo.split(','),"scroll"] : ["scroll"];
            dispatch(changeCheckInfo(newValue));
            localStorage.setItem('checkInfo', newValue.join(','));
            if(!isCloseSecondModal){
                dispatch(changeModalSecondKey(true));
            }
            const oldKeys = localStorage.getItem('keysNumber');

            const newKey = Number(oldKeys)+1;

            dispatch(changeCurrentKeys(newKey));
            localStorage.setItem('keysNumber',newKey.toString())
        }

        dispatch(changeModalPrinciples(false))






    }

    return (
        <Modal
            active={modalPrinciples}
            onClose={onCloseEx}
        >
            <ModalInfo
                title={'The Burning Man Principles'}
                buttons={<>

                <Button onClick={onCloseEx} className={'button--primary'} name={'Continue'} />
                </>}
            >
                <ol>
                    <li><span>Radical Inclusion:</span> Everyone is welcome at Burning Man. Turn strangers into friends by saying hello to friendly faces on the dance floor and inviting passersby into your camp for activities.</li>
                    <li><span>Gifting:</span> Offer a hug to a stranger, or lend an ear to someone who needs to talk. Make buttons or other trinkets to give away. Provide tarot card readings or dance lessons. Everyone giving something of themselves on the playa is what makes the event so special.</li>
                    <li><span>Decommodification:</span> Burning Man is a breather from the ads and over commercialism of the default world. Leave your clothing with logos at home and tuck your wallet away, while experiencing the pleasures of life without commercial transactions. If you really need something, barter for it!</li>
                    <li><span>Radical Self-Reliance:</span> Burning Man is a physically, and sometimes mentally and emotionally, challenging environment. Make sure you have everything you need for a successful journey, from the right footwear to the right attitude.</li>
                    <li><span>Radical Self Expression:</span> Bring that pink wig you’ve had tucked away in your closet and wear it with gusto! Choreograph a dance and perform it on the playa! Serenade your campmates! Contributing your unique self to the event is part of what makes it so magical.</li>
                    <li><span>Communal Effort:</span> No camp or installation at Burning Man could happen without group effort. Whether it’s cooking breakfast and sunrise or getting your art car ready to ride, group efforts are what make Burning Man happen, and what make it so much fun.</li>
                    <li><span>Civic Responsibility:</span> Respect your campmates! Respect your neighbors! Respect everyone in Black Rock City, including law enforcement! Taking responsibility for public welfare keeps Black Rock City safe and spirited.</li>
                    <li><span>Leaving No Trace:</span> If you see any garbage on the ground, pick it up! Make sure that nothing falls out of your bag to become MOOP. And make a plan for getting your trash off the playa. If you bring it in, you’ve got to bring it out. Burning Man’s government permit relies on Burners leaving the site clean.</li>
                    <li><span>Participation:</span> Burning Man is not for spectators. Put on that outfit. Get on the dancefloor. Sing. Spin. Scream. Go out and play! There are limitless ways to participate on the playa, so just keep an open mind and go where you feel called to.</li>
                    <li><span>Immediacy:</span> Burning Man is best experienced in the present moment. While your phone might work, you’re highly encouraged to turn it off and participate in what’s going on around you.</li>
                </ol>

            </ModalInfo>
        </Modal>
    );
};

export default ModalPrinciples;