import './Modal.scss';
import React, {FC, ReactNode, useEffect} from 'react';
import BackIcon from "../../icons/BackIcon";
import CloseIcon from "../../icons/CloseIcon";
import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";
import {changeDisabledCanvasHover} from "../../../redux/slices/appSlice";

interface IModal{
    active?: boolean;
    children?: ReactNode;
    back?: boolean;
    onClose?: ()=>void;
    onBack?: ()=>void;
}

const Modal:FC<IModal> = ({
                              back=false,
                              active= false,
                              children,
                              onClose=()=>{},
                              onBack=()=>{}
                          }) => {
    const {modalBurningMan} = useSelectorEx(state => state.modals)
    const dispatch = useDispatchEx();
    useEffect(() => {
        if(active){
                dispatch(changeDisabledCanvasHover(false))
        }
    }, [active]);

    const onCloseEx = ()=>{
        const keysNumber =  localStorage.getItem('keysNumber');

        if(!modalBurningMan && keysNumber)
        {
            dispatch(changeDisabledCanvasHover(true));
        }

        onClose();
    }
    return (
        <div className={`modal ${active ? "active": ""}`}>
            <div onClick={onCloseEx} className="modal__overlay"></div>
            <div className="modal__wrapper">
                <div className="modal__controls">
                    <a className={back ? "hide": ""} onClick={onBack}><BackIcon/></a>
                    <a onClick={onCloseEx}><CloseIcon/></a>
                </div>
                <div className="modal__inner">
                    <div className="modal__content">
                        {children}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Modal;