import './MobileControls.scss';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import ArrowIcon from "../../icons/ArrowIcon";
import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";
import {
    changeModalAccommodation,
    changeModalBurningMan,
    changeModalClans, changeModalGetAround, changeModalGetThere, changeModalMeals
} from "../../../redux/slices/modalSlice/modalSlice";
import {changeIsTappedTitle} from "../../../redux/slices/appSlice";

/*const positionData:any = {
    1: {
        title: "Accommodation",
        x: -499,
        y: -521,
        action: ()=> changeModalAccommodation(true)
    },
    2: {
        title: "Meal",
        x: -660,
        y: -500,
        action: ()=> changeModalMeals(true)
    },
    3: {
        title: "The man",
        x: -972,
        y: -500,
        action: ()=> changeModalBurningMan(true)
    },
    4: {
        title: "Clan",
        x: -1018,
        y: -470,
        action: ()=> changeModalClans(true)
    },
    5: {
        title: "Mobility",
        x: -1252,
        y: -520,
        action: ()=> changeModalGetAround(true)
    },
    6: {
        title: "Travel",
        x: -1390,
        y: -636.5,
        action: ()=> changeModalGetThere(true)
    },

}*/
const positionData:any = {
    1: {
        title: "Accommodation",
        x: 280,
        y: 230,
        action: ()=> changeModalAccommodation(true)
    },
    2: {
        title: "Meal",
        x: 330,
        y: 250,
        action: ()=> changeModalMeals(true)
    },
    3: {
        title: "The man",
        x: 640,
        y: 290,
        action: ()=> changeModalBurningMan(true)
    },
    4: {
        title: "Clan",
        x: 690,
        y: 230,
        action: ()=> changeModalClans(true)
    },
    5: {
        title: "Mobility",
        x: 915,
        y: 255,
        action: ()=> changeModalGetAround(true)
    },
    6: {
        title: "Travel",
        x: 1080,
        y: 450,
        action: ()=> changeModalGetThere(true)
    },

}

const updateGlobalMapElementStyle = (step:number,type=1) => {
    const globalMapElement = document.querySelector('.global-map__canvas') as HTMLElement;
    if (!globalMapElement) return;

    const { x, y } = positionData[step];
    const centerX = -x  + window.innerWidth /3.5 ;
   // const calcY = type
    const centerY = -y + (window.innerHeight > 900 ? window.innerHeight / 3 : window.innerHeight / 3) //+ //(window.innerHeight > 900 ? window.innerHeight / 2 : window.innerHeight / 2) ; //- window.innerHeight / (step === 6 ? 2: 2);

    globalMapElement.style.transition = "transform 0.3s ease";
    globalMapElement.style.transform = `translate(${centerX}px, ${centerY}px)`;

    setTimeout(() => {
        globalMapElement.style.transition = 'none';
    }, 1000);
};

const MobileControls:FC = () => {
    const { checkAccommodation,checkMeal,checkGetThere,checkGetAround,checkClans} = useSelectorEx(state => state.checks);
    const { currentKeys, isTappedTitle,firstEmail } = useSelectorEx(state => state.app);
    const [step, setStep] = useState(3);
    const currentName = useMemo(() => positionData[step].title, [step]);
    const dispatch = useDispatchEx();

    const updateStep = useCallback((newStep:number) => {
        if (!currentKeys) {
            setStep(newStep);
            updateGlobalMapElementStyle(newStep);
            if (!isTappedTitle) {
                dispatch(changeIsTappedTitle(true));
            }
        }
    }, [currentKeys, isTappedTitle, dispatch]);

    const clickNext = useCallback(() => {
        if (step < 6){
            updateStep(step + 1);
        }
        else{
            updateStep(1);
        }
    }, [step, updateStep]);

    const clickPrev = useCallback(() => {
        if (step > 1){
            updateStep(step - 1);
        }
        else{
            updateStep(6);
        }
    }, [step, updateStep]);

    const onClickTitle = () => {
        dispatch(positionData[step].action());
    };

    useEffect(() => {
        updateGlobalMapElementStyle(step);
    }, [step]);

    useEffect(() => {
        if(firstEmail){
            if(window.innerWidth < 1200){
                setTimeout(()=>{
                    updateGlobalMapElementStyle(step);
                },1000)
            }
        }
    }, [firstEmail]);

    useEffect(() => {
        //checkAccommodation,checkMeal,checkGetThere,checkGetAround,checkClans
        if(checkAccommodation && checkMeal && checkGetThere && checkGetAround && checkClans){
            if(window.innerWidth < 1200){
                setTimeout(()=>{
                    updateGlobalMapElementStyle(3);
                },1000)
            }
        }
    }, [checkAccommodation,checkMeal,checkGetThere,checkGetAround,checkClans]);

    return (
        <div className={`mobile-controls ${currentKeys > 0 ? "hidden" : ""}`}>
         {/*   <div onClick={clickPrev} className="mobile-controls__arrow mobile-controls__prev">
                <ArrowIcon />
            </div>
            <div className={`mobile-controls__title ${isTappedTitle ? "active" : ""}`} onClick={onClickTitle}>
                {currentName}
            </div>
            <div onClick={clickNext} className="mobile-controls__arrow mobile-controls__next">
                <ArrowIcon />
            </div>*/}
        </div>
    );
};


export default MobileControls;