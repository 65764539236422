import {FC, memo, useCallback, useEffect, useRef,useMemo} from 'react';
import { Sprite} from "@pixi/react";
import useCalculateSize from "../../../hooks/useCalculateSize";
import useWindowSize from "../../../hooks/useWindowSize";
import {GlowFilter} from '@pixi/filter-glow';
import * as PIXI from "pixi.js";
// First Type: Original Path with "Big" suffix
import ring_menBig from "../../../assets/img/second_items/ring-men.png";
import airportBig from "../../../assets/img/second_items/airport.png";
import airplaneBig from "../../../assets/img/second_items/airplane.png";
import scrollBig from "../../../assets/img/second_items/scroll.png";
import tempeBig from "../../../assets/img/second_items/tempe.png";
import artcarBig from "../../../assets/img/second_items/artcar.png";
import bunnyBig from "../../../assets/img/second_items/bunny.png";
import cakesBig from "../../../assets/img/second_items/cakes.png";
import iceBig from "../../../assets/img/second_items/ice.png";
import mailboxBig from "../../../assets/img/second_items/mailbox.png";
import repair_bycicleBig from "../../../assets/img/second_items/repair_bycicle.png";
import medicBig from "../../../assets/img/second_items/medic.png";
import time_3Big from "../../../assets/img/second_items/time-3.png";
import time_9Big from "../../../assets/img/second_items/time-9.png";

import ebikesBig from "../../../assets/img/second_items/ebikes.png";
import bikesBig from "../../../assets/img/second_items/bikes.png";
import main_campBig from "../../../assets/img/second_items/main_camp.png";

// Second Type: Modified Path with "Normal" suffix
import ring_menNormal from "../../../assets/img/second_items/normal/ring-men.png";
import airportNormal from "../../../assets/img/second_items/normal/airport.png";
import airplaneNormal from "../../../assets/img/second_items/normal/airplane.png";
import scrollNormal from "../../../assets/img/second_items/normal/scroll.png";
import tempeNormal from "../../../assets/img/second_items/normal/tempe.png";
import artcarNormal from "../../../assets/img/second_items/normal/artcar.png";
import bunnyNormal from "../../../assets/img/second_items/normal/bunny.png";
import cakesNormal from "../../../assets/img/second_items/normal/cakes.png";
import iceNormal from "../../../assets/img/second_items/normal/ice.png";
import mailboxNormal from "../../../assets/img/second_items/normal/mailbox.png";
import repair_bycicleNormal from "../../../assets/img/second_items/normal/repair_bycicle.png";
import medicNormal from "../../../assets/img/second_items/normal/medic.png";
import time_3Normal from "../../../assets/img/second_items/normal/time-3.png";
import time_9Normal from "../../../assets/img/second_items/normal/time-9.png";
import ebikesNormal from "../../../assets/img/second_items/normal/ebikes.png";
import bikesNormal from "../../../assets/img/second_items/normal/bikes.png";
import main_campNormal from "../../../assets/img/second_items/normal/main_camp.png";

import {gsap} from "gsap";
import hide from "../../../assets/img/hide.png";
import {
    changeModalArtCar,
    changeModalBurningMan,
    changeModalInfo,
    changeModalInfoCurrent,
    changeModalPrinciples
} from "../../../redux/slices/modalSlice/modalSlice";

import {animationFinish, animationStart} from "../../../helpers/animations";
import { Rectangle, Texture } from 'pixi.js';
import useCroppedTextures from "../../../hooks/useCroppedTextures";

import {
    airportCropRectangles,
    airportCropRectanglesW2,
    bikesCropRectangles,
    bikesCropRectanglesW2,
    bunnyCropRectangles,
    bunnyCropRectanglesW2,
    cakesCropRectangles,
    cakesCropRectanglesW2,
    ebikesCropRectangles,
    ebikesCropRectanglesW2,
    iceCropRectangles,
    iceCropRectanglesW2,
    longFengCarCropRectangles,
    longFengCarCropRectanglesW2,
    mailCropRectangles,
    mailCropRectanglesW2,
    mainCampCropRectangles,
    mainCampCropRectanglesW2,
    medicCropRectangles,
    medicCropRectanglesW2,
    repairCropRectangles,
    repairCropRectanglesW2,
    ringManCropRectangles, ringManCropRectanglesW2,
    scrollCropRectangles,
    scrollCropRectanglesW2,
    tempeCropRectangles, tempeCropRectanglesW2,
    time3CropRectangles,
    time3CropRectanglesW2,
    time9CropRectangles,
    time9CropRectanglesW2
} from "./cropImageData";


interface IAnimationsItems{
    dispatch?:any;
    isOpenKeyModal?:boolean;
    mapLoading?: boolean;
    loadedApp?: boolean;
    interactive?:boolean;
    checks?:string[];
    currentKey?:any;
    firstEmail?:boolean;
}
const imagesSizesList = {
    2600:[
        ring_menNormal,
        airportNormal,
        airplaneNormal,
        scrollNormal,
        tempeNormal,
        artcarNormal,
        bunnyNormal,
        cakesNormal,
        iceNormal,
        mailboxNormal,
        repair_bycicleNormal,
        medicNormal,
        time_3Normal,
        time_9Normal,
        ebikesNormal,
        bikesNormal,
        main_campNormal
    ],
    3800:[
        ring_menBig,
        airportBig,
        airplaneBig,
        scrollBig,
        tempeBig,
        artcarBig,
        bunnyBig,
        cakesBig,
        iceBig,
        mailboxBig,
        repair_bycicleBig,
        medicBig,
        time_3Big,
        time_9Big,
        ebikesBig,
        bikesBig,
        main_campBig
    ]
}
const [
    ring_men,
    airport,
    airplane,
    scroll,
    tempe,
    artcar,
    bunny,
    cakes,
    ice,
    mailbox,
    repair_bycicle,
    medic,
    time_3,
    time_9,
    ebikes,
    bikes,
    main_camp
] = window.innerWidth > 2600 ? imagesSizesList[3800]: imagesSizesList[2600];


const isLargeScreen = window.innerWidth > 2600;

const allImagesHoverCropRectangles = {
    "longFengCar": isLargeScreen ? longFengCarCropRectangles : longFengCarCropRectanglesW2,
    "bikes": isLargeScreen ? bikesCropRectangles : bikesCropRectanglesW2,
    "airport": isLargeScreen ? airportCropRectangles : airportCropRectanglesW2,
    "bunny": isLargeScreen ? bunnyCropRectangles : bunnyCropRectanglesW2,
    "cakes": isLargeScreen ? cakesCropRectangles : cakesCropRectanglesW2,
    "ice": isLargeScreen ? iceCropRectangles : iceCropRectanglesW2,
    "mail": isLargeScreen ? mailCropRectangles : mailCropRectanglesW2,
    "medic": isLargeScreen ? medicCropRectangles : medicCropRectanglesW2,
    "repair": isLargeScreen ? repairCropRectangles : repairCropRectanglesW2,
    "time3": isLargeScreen ? time3CropRectangles : time3CropRectanglesW2,
    "time9": isLargeScreen ? time9CropRectangles : time9CropRectanglesW2,
    "ebikes": isLargeScreen ? ebikesCropRectangles : ebikesCropRectanglesW2,
    "mainCamp": isLargeScreen ? mainCampCropRectangles : mainCampCropRectanglesW2,
    "scroll": isLargeScreen ? scrollCropRectangles : scrollCropRectanglesW2,
    "tempe": isLargeScreen ? tempeCropRectangles : tempeCropRectanglesW2,
    "ringMan": isLargeScreen ? ringManCropRectangles : ringManCropRectanglesW2,
};
/*
const allImagesHoverCropRectangles = {
    "longFengCar": longFengCarCropRectangles,
    "bikes": bikesCropRectangles,
    "airport": airportCropRectangles,
    "bunny": bunnyCropRectangles,
    "cakes": cakesCropRectangles,
    "ice": iceCropRectangles,
    "mail": mailCropRectangles,
    "medic": medicCropRectangles,
    "repair": repairCropRectangles,
    "time3": time3CropRectangles,
    "time9": time9CropRectangles,
    "ebikes": ebikesCropRectangles,
    "mainCamp": mainCampCropRectangles,
    "scroll": scrollCropRectangles,
    "tempe": tempeCropRectangles,
    "ringMan":ringManCropRectangles,
}
*/


const SecondItems:FC<IAnimationsItems> = memo(({isOpenKeyModal=false,firstEmail,currentKey,checks=[],interactive=true,dispatch=()=>{},mapLoading=false,loadedApp=false}) => {
    const calculateSize = useCalculateSize();
    const {width,height} = useWindowSize();
    const filterStage = useRef<any>(null);
    const currentItem = useRef<any>(null);
    const currentItemHover = useRef<any>(null);
    const currentItemClick = useRef<any>(null);
    const timeTouchRef = useRef<any>(null)
    const currentAlpha = useRef<number>(0);


    const campRef = useRef<any>(null);
    const ringRef = useRef<any>(null);
    const airportRef = useRef<any>(null);
    const airplaneRef = useRef<any>(null);
    const scrollRef = useRef<any>(null);
    const tempeRef = useRef<any>(null);
    const artcarRef = useRef<any>(null);
    const bunnyRef = useRef<any>(null);
    const cakesRef = useRef<any>(null);
    const iceRef = useRef<any>(null);
    const mailboxRef1 = useRef<any>(null);
    const mailboxRef2 = useRef<any>(null);
    const mailboxRef3 = useRef<any>(null);
    const medicRef1 = useRef<any>(null);
    const medicRef2 = useRef<any>(null);
    const repairBicycleRef = useRef<any>(null);
    const time3Ref = useRef<any>(null);
    const time9Ref = useRef<any>(null);
    const ebikesRef = useRef<any>(null);
    const bikesRef = useRef<any>(null);

    const isLoaded = useRef<any>();
    useEffect(() => {

        let timeoutId: NodeJS.Timeout | null = null;
        const needEmail =  localStorage.getItem('burningEmail') ? localStorage.getItem('burningEmail') : firstEmail;
        if( (mapLoading && loadedApp) && !isLoaded.current && needEmail ){
            timeoutId=setTimeout(() => {
               const allRefs = [campRef,
                    ringRef,
                    airportRef,
                    airplaneRef,
                    scrollRef,
                    tempeRef,
                    artcarRef,
                    bunnyRef,
                    cakesRef,
                    iceRef,
                   ];

                const secondTimeRefs = [
                    mailboxRef1,
                    mailboxRef2,
                    mailboxRef3,
                    medicRef1,
                    medicRef2,
                    repairBicycleRef,
                    time3Ref,
                    time9Ref,

                    ebikesRef,
                    bikesRef
                ]

                //  myMatrix.translate(1000, 500);
                const timeline = gsap.timeline();
             //   timeline.fromTo(ringRef.current, animationStart(ringRef.current),animationFinish(ringRef.current),0.3);
                allRefs.forEach((ref,index) => {
                    if (ref.current) {
                        timeline.fromTo(ref.current, animationStart(ref.current), animationFinish(ref.current), 1.3);
                    }
                });
                secondTimeRefs.forEach((ref,index) => {
                    if (ref.current) {
                        timeline.fromTo(ref.current, animationStart(ref.current), animationFinish(ref.current), 2.0);
                    }
                });

                isLoaded.current = true;
                setTimeout(()=>{
                    currentAlpha.current = 1;
                },2000)


            },1000)
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [mapLoading,width,height,loadedApp,firstEmail]);

    useEffect(() => {
        if( (mapLoading && loadedApp) && isLoaded.current) {
            if (checks.length && currentItemClick.current) {
                currentItemClick.current.filters = [];
                gsap.to(filterStage.current, {
                    pixi: {
                        alpha: 0,
                    },
                    duration: 0.7
                });
                visibleAll();
            }
        }
    }, [checks]);
    useEffect(() => {
        if( mapLoading && loadedApp){
            const timelineInteractive = gsap.timeline();


            visibleAll();


                        gsap.set(currentItem.current, {
                            pixi:{
                                zIndex:3
                            }
                        });
            if(interactive){

                timelineInteractive.to(filterStage.current, {
                    pixi: {
                        alpha: 0,
                    },
                    duration: 0.3
                },0);

                  timelineInteractive.set(currentItem.current, {
                      pixi:{
                          zIndex:1
                      }
                  },0.4);



            }

        }
    }, [interactive]);
    function visibleAll(){
        const alphaAnimate = {
            pixi:{
                alpha:1
            }
        }
        const allRefs = [campRef,
            ringRef,
            airportRef,
            airplaneRef,
            scrollRef,
            tempeRef,
            artcarRef,
            bunnyRef,
            cakesRef,
            iceRef,

            mailboxRef1,
            mailboxRef2,
            mailboxRef3,
            medicRef1,
            medicRef2,
            repairBicycleRef,
            time3Ref,
            time9Ref,

            ebikesRef,
            bikesRef
        ];
        const timelineEx = gsap.timeline();
       allRefs.forEach((ref,index) => {
            if (ref.current) {
                timelineEx.set(ref.current, alphaAnimate);
            }
        });
    }

    const timeline = gsap.timeline();


    const openModalInfo = useCallback((name: string)=>{
        if(!currentKey) return;
        if(width < 1200 && timeTouchRef.current+100 <= new Date().getTime()) return
        dispatch(changeModalInfo(true));
        dispatch(changeModalInfoCurrent(name))
    },[currentKey])
    const onTouchStart = () => {
        timeTouchRef.current = new Date().getTime();
    }

    const defaultIndex = 0;
    const defaultAlpha = (currentAlpha.current) ? currentAlpha.current :  0;


    useEffect(() => {
        if( mapLoading && loadedApp && isLoaded.current){
            if(width < 1200){
                const allRefs = [campRef,
                    ringRef,
                    airportRef,
                    airplaneRef,
                    scrollRef,
                    tempeRef,
                    artcarRef,
                    bunnyRef,
                    cakesRef,
                    iceRef,

                    mailboxRef1,
                    mailboxRef2,
                    mailboxRef3,
                    medicRef1,
                    medicRef2,
                    repairBicycleRef,
                    time3Ref,
                    time9Ref,

                    ebikesRef,
                    bikesRef
                ];
                const timelineEx = gsap.timeline();
            /*    allRefs.forEach((ref,index) => {
                    if (ref.current) {
                        timelineEx.set(ref.current, {
                            pixi:{
                                a:0.7
                            }
                        });
                    }
                });*/
            }
        }
    }, [width]);
    const artcarSprite = useCroppedTextures(artcar, allImagesHoverCropRectangles['longFengCar']);
    const bikesSprite = useCroppedTextures(bikes, allImagesHoverCropRectangles['bikes']);
    const airportSprite = useCroppedTextures(airport, allImagesHoverCropRectangles['airport']);
    const bunnySprite = useCroppedTextures(bunny, allImagesHoverCropRectangles['bunny']);
    const cakesSprite = useCroppedTextures(cakes, allImagesHoverCropRectangles['cakes']);
    const iceSprite = useCroppedTextures(ice, allImagesHoverCropRectangles['ice']);
    const mailSprite = useCroppedTextures(mailbox, allImagesHoverCropRectangles['mail']);
    const medicSprite = useCroppedTextures(medic, allImagesHoverCropRectangles['medic']);
    const repairSprite = useCroppedTextures(repair_bycicle, allImagesHoverCropRectangles['repair']);
    const time3Sprite = useCroppedTextures(time_3, allImagesHoverCropRectangles['time3']);
    const time9Sprite = useCroppedTextures(time_9, allImagesHoverCropRectangles['time9']);
    const ebikesSprite = useCroppedTextures(ebikes, allImagesHoverCropRectangles['ebikes']);
    const mainCampSprite = useCroppedTextures(main_camp, allImagesHoverCropRectangles['mainCamp']);
    const scrollSprite = useCroppedTextures(scroll, allImagesHoverCropRectangles['scroll']);
    const tempeSprite = useCroppedTextures(tempe, allImagesHoverCropRectangles['tempe']);
    const ringManSprite = useCroppedTextures(ring_men, allImagesHoverCropRectangles['ringMan']);


    //ringManCropRectangles
    const allSprites:any = {
        "longFengCar" : artcarSprite,
        "bikes": bikesSprite,
        "airport":airportSprite,
        "bunny": bunnySprite,
        "cakes": cakesSprite,
        "ice": iceSprite,
        "mail": mailSprite,
        "medic": medicSprite,
        "repair":repairSprite,
        "time3": time3Sprite,
        "time9": time9Sprite,
        "ebikes": ebikesSprite,
        "mainCamp": mainCampSprite,
        "scroll": scrollSprite,
        "tempe": tempeSprite,
        "ringMan": ringManSprite

    };
    const onChangeOverNew = (e:any,current:string,showed='none')=>{
        if(!currentKey) return;
        if (!interactive) return;
        if(!e.target) return;
        if(!checks.includes(showed)){
            gsap.to(e.target, {
                duration: 0, // Каждую секунду
                onFinish: () => {
                    // Логика для переключения текстуры при каждом повторении

                    let currentTexture = e.target.texture;
                    e.target.texture = allSprites[current][0];
                    //bicycle1Texture.current = bicycle1.current.texture
                },
                ease: "linear"
            });
        }

        document.body.style.cursor = 'pointer';

        gsap.to(e.target, {
            pixi: {
                zIndex: 3
            },
            duration: 0.1
        });

        gsap.to(filterStage.current,{
            pixi: {
                alpha: 1,

            },
            duration: 0.7
        });
        currentItemClick.current = e.target;
        currentItemHover.current = current;
        currentItem.current = e.target;
    }
    const onChangeOutNew = (e:any,current:string,showed='none')=>{
        if(!currentKey) return;
        if (!interactive) return;
        if(!e.target) return;
        if(!checks.includes(showed)) {
            gsap.to(e.target, {
                duration: 0, // Каждую секунду
                onFinish: () => {
                    // Логика для переключения текстуры при каждом повторении

                    let currentTexture = e.target.texture;
                    e.target.texture = allSprites[current][1];
                    //bicycle1Texture.current = bicycle1.current.texture
                },
                ease: "linear"
            });
        }
        document.body.style.cursor = 'default';

        gsap.to(e.target, {
            pixi: {
                zIndex: 1
            },
            duration: 0.1
        });

        gsap.to(filterStage.current,{
            pixi: {
                alpha: 0,
            },
            duration: 0.7
        });
        currentItemHover.current = null;
        currentItem.current = null
    }


    return (
        <>
            <Sprite
                zIndex={2}
                alpha={0}
                ref={filterStage}
                width={calculateSize(5760)}
                height={calculateSize(3240)}
                x={0}
                y={0}
                image={hide}
            />
            <Sprite
                ref={campRef}
                zIndex={-1}
                alpha={defaultAlpha}
                x={calculateSize(1433)}
                y={calculateSize(1557)}
                width={calculateSize(709)}
                height={calculateSize(703)}
                interactive={true}//!checks.includes('centerCamp')
                //image={main_camp}
                texture={currentKey ? (checks.includes('centerCamp') ? mainCampSprite[0]:mainCampSprite[1]) : mainCampSprite[0]}//875, heig

                hitArea={new Rectangle(0, 0, 450, 559)}
                onmouseover={(e)=>onChangeOverNew(e,'mainCamp','centerCamp')}
                onmouseout={(e)=>onChangeOutNew(e,'mainCamp','centerCamp')}
                onclick={()=>openModalInfo('centerCamp')}
                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('centerCamp')}
               // filters={checks.includes('centerCamp') ? []:[contrastFilterEx]}

            />
           <Sprite
                ref={ringRef} //ringManSprite
                alpha={defaultAlpha}
                x={calculateSize(183)}
                y={calculateSize(2207)}
                width={calculateSize(511)}
                height={calculateSize(584)}
               // image={ring_men}
                texture={currentKey ? (checks.includes('entranceGate') ? ringManSprite[0] : ringManSprite[1]) : ringManSprite[0]}
                interactive={true}//!checks.includes('entranceGate')
                onmouseover={(e)=>onChangeOverNew(e,'ringMan','entranceGate')}
                onmouseout={(e)=>onChangeOutNew(e,'ringMan','entranceGate')}
                onclick={()=>openModalInfo('entranceGate')}
                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('entranceGate')}
             //   filters={checks.includes('entranceGate') ? []:[contrastFilterEx]}
            />

            <Sprite
                ref={airportRef}
                alpha={defaultAlpha}
                x={calculateSize(3303)}
                y={calculateSize(2377)}
             /*   width={calculateSize(566)}
                height={calculateSize(588)}*/
                width={calculateSize(630)}
                height={calculateSize(666)}
                texture={currentKey ? (checks.includes('airport') ? airportSprite[0]:airportSprite[1]): airportSprite[0]}//875, height: 757
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'airport','airport')}
                onmouseout={(e)=>onChangeOutNew(e,'airport','airport')}
                onclick={()=>openModalInfo('airport')}
                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('airport')}
               // filters={checks.includes('airport') ? []:[contrastFilterEx]}
            />
            <Sprite
                ref={airplaneRef}
                alpha={defaultAlpha}
                x={calculateSize(3803)}
                y={calculateSize(2577)}
                width={calculateSize(393)}
                height={calculateSize(316)}
                image={airplane}
                interactive={false}
            />

            <Sprite
                ref={scrollRef} //scrollSprite
                alpha={defaultAlpha}
                x={calculateSize(4713)}
                y={calculateSize(2257)}
                width={calculateSize(586)}
                height={calculateSize(670)}
                //image={scroll}

                texture={currentKey ? (checks.includes('scroll') ? scrollSprite[0]:scrollSprite[1]): scrollSprite[0]}//875, height: 757

                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'scroll','scroll')}
                onmouseout={(e)=>onChangeOutNew(e,'scroll','scroll')}
                onclick={()=>(!currentKey) ? {}: dispatch(changeModalPrinciples(true))}
               // tap={()=>(!currentKey) ? {}: dispatch(changeModalPrinciples(true))}

                ontouchstart={onTouchStart}
                ontouchend={()=>{
                    if(width < 1200 && timeTouchRef.current+100 <= new Date().getTime()) return;
                    return (!currentKey) ? {}: dispatch(changeModalPrinciples(true))
                }}
              //  filters={checks.includes('scroll') ? []:[contrastFilterEx]}
            />

            <Sprite
                ref={tempeRef}
                alpha={defaultAlpha}
                x={calculateSize(3003)}
                y={calculateSize(467)}
                width={calculateSize(786)}
                height={calculateSize(878)}
                texture={currentKey ? (checks.includes('temple') ? tempeSprite[0]:tempeSprite[1]): tempeSprite[0]}//875, height: 757
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'tempe','temple')}
                onmouseout={(e)=>onChangeOutNew(e,'tempe','temple')}

                onclick={()=>openModalInfo('temple')}
                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('temple')}
              //  filters={checks.includes('temple') ? []:[contrastFilterEx]}
            />

            <Sprite
                ref={artcarRef}
                alpha={defaultAlpha}
                x={calculateSize(3783)}
                y={calculateSize(417)}
            /*    width={calculateSize(465)}
                height={calculateSize(475)}*/

                width={calculateSize(875/1.7)}
                height={calculateSize(757/1.7)}
                texture={currentKey ? (checks.includes('longFengCar') ? artcarSprite[0]:artcarSprite[1]):artcarSprite[0]}//875, height: 757
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'longFengCar','longFengCar')}
                onmouseout={(e)=>onChangeOutNew(e,'longFengCar','longFengCar')}
                onclick={()=>(!currentKey) ? {}: dispatch(changeModalArtCar(true))}
             //   tap={()=>(!currentKey) ? {}: dispatch(changeModalArtCar(true))}

                ontouchstart={onTouchStart}
                ontouchend={()=>{
                    if(width < 1200 && timeTouchRef.current+100 <= new Date().getTime()) return;
                    return (!currentKey) ? {}: dispatch(changeModalArtCar(true))
                }}
                //filters={checks.includes('longFengCar') ? []:[contrastFilterEx]}

            />

            <Sprite
                ref={bunnyRef}
                alpha={defaultAlpha}
                x={height < 700 || width < 1200 ?  calculateSize(5153): calculateSize(5183)}
                y={height < 700 || width < 1200  ?  calculateSize(537): calculateSize(317)}
               /* width={calculateSize(332)}
                height={calculateSize(357)}*/

                width={calculateSize(434)}
                height={calculateSize(454)}
                texture={currentKey ? (checks.includes('artCars') ? bunnySprite[0]:bunnySprite[1]):bunnySprite[0]}//875, height: 757
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'bunny','artCars')}
                onmouseout={(e)=>onChangeOutNew(e,'bunny','artCars')}
                onclick={()=>openModalInfo('artCars')}
               // tap={()=>openModalInfo('artCars')}

                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('artCars')}
              //  filters={checks.includes('artCars') ? []:[contrastFilterEx]}
            />

            <Sprite
                ref={cakesRef}
                alpha={defaultAlpha}
                x={calculateSize(4503)}
                y={calculateSize(837)}
                width={calculateSize(342)}
                height={calculateSize(341)}
               // image={cakes}
                texture={currentKey ? (checks.includes('deepPlaya') ? cakesSprite[0] : cakesSprite[1]):cakesSprite[0]}//875, height: 757
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'cakes','deepPlaya')}
                onmouseout={(e)=>onChangeOutNew(e,'cakes','deepPlaya')}
                onclick={()=>openModalInfo('deepPlaya')}
               // tap={()=>openModalInfo('deepPlaya')}

                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('deepPlaya')}
               // filters={checks.includes('deepPlaya') ? []:[contrastFilterEx]}
            />

            <Sprite
                ref={iceRef}
                alpha={defaultAlpha}
                x={calculateSize(1073)}
                y={calculateSize(1737)}
                width={calculateSize(531)}
                height={calculateSize(612)}
              //  image={ice}
                texture={currentKey ? (checks.includes('arctica') ? iceSprite[0] : iceSprite[1]):iceSprite[0]}
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'ice','arctica')}
                onmouseout={(e)=>onChangeOutNew(e,'ice','arctica')}
                onclick={()=>openModalInfo('arctica')}
               // tap={()=>openModalInfo('arctica')}

                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('arctica')}
               // filters={checks.includes('arctica') ? []:[contrastFilterEx]}
            />

            <Sprite
                ref={mailboxRef1}
                alpha={defaultAlpha}
                x={calculateSize(1450)}
                y={calculateSize(1910)}
                width={calculateSize(245)}
                height={calculateSize(401)}
              //  image={mailbox}
                texture={currentKey ? (checks.includes('postOffice') ? mailSprite[0] : mailSprite[1]):mailSprite[0]}
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'mail','postOffice')}
                onmouseout={(e)=>onChangeOutNew(e,'mail','postOffice')}
                onclick={()=>openModalInfo('postOffice')}
             //   tap={()=>openModalInfo('postOffice')}

                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('postOffice')}
               // filters={checks.includes('postOffice') ? []:[contrastFilterEx]}//mailSprite
            />

            <Sprite
                ref={mailboxRef2}
                alpha={defaultAlpha}
                x={calculateSize(1960)}
                y={calculateSize(805)}
               /* width={calculateSize(156)}
                height={calculateSize(309)}*/
                width={calculateSize(245)}
                height={calculateSize(401)}

                texture={currentKey ? (checks.includes('postOffice') ? mailSprite[0] : mailSprite[1]):mailSprite[0]}
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'mail','postOffice')}
                onmouseout={(e)=>onChangeOutNew(e,'mail','postOffice')}
                onclick={()=>openModalInfo('postOffice')}
             //   tap={()=>openModalInfo('postOffice')}


                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('postOffice')}
              //  filters={checks.includes('postOffice') ? []:[contrastFilterEx]}
            />

            <Sprite
                ref={mailboxRef3}
                alpha={defaultAlpha}
                x={calculateSize(3255)}
                y={calculateSize(1595)}
                width={calculateSize(245)}
                height={calculateSize(401)}
                texture={currentKey ? (checks.includes('postOffice') ? mailSprite[0] : mailSprite[1]):mailSprite[0]}
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'mail','postOffice')}
                onmouseout={(e)=>onChangeOutNew(e,'mail','postOffice')}
                onclick={()=>openModalInfo('postOffice')}
                //tap={()=>openModalInfo('postOffice')}

                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('postOffice')}
             //   filters={checks.includes('postOffice') ? []:[contrastFilterEx]}
            />

            <Sprite
                ref={medicRef1}
                alpha={defaultAlpha}
                x={calculateSize(1575)}
                y={calculateSize(695)}
                width={calculateSize(308)}
                height={calculateSize(273)}
                texture={currentKey ? (checks.includes('medicalTents') ? medicSprite[0] : medicSprite[1]):medicSprite[0]}
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'medic','medicalTents')}
                onmouseout={(e)=>onChangeOutNew(e,'medic','medicalTents')}
                onclick={()=>openModalInfo('medicalTents')}
          //      tap={()=>openModalInfo('medicalTents')}

                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('medicalTents')}
               // filters={checks.includes('medicalTents') ? []:[contrastFilterEx]}
            />

            <Sprite
                ref={medicRef2}
                alpha={defaultAlpha}
                x={calculateSize(3490)}
                y={calculateSize(1835)}
                width={calculateSize(308)}
                height={calculateSize(273)}
                texture={currentKey ? (checks.includes('medicalTents') ? medicSprite[0] : medicSprite[1]):medicSprite[0]}
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'medic','medicalTents')}
                onmouseout={(e)=>onChangeOutNew(e,'medic','medicalTents')}
                onclick={()=>openModalInfo('medicalTents')}
               // tap={()=>openModalInfo('medicalTents')}

                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('medicalTents')}
              //  filters={checks.includes('medicalTents') ? []:[contrastFilterEx]}
            />

            <Sprite
                ref={repairBicycleRef}
                alpha={defaultAlpha}
                x={calculateSize(1715)}
                y={calculateSize(695)}
                width={calculateSize(319)}
                height={calculateSize(360)}
                texture={currentKey ? (checks.includes('playaBikeRepair') ? repairSprite[0] : repairSprite[1]):repairSprite[0]}
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'repair','playaBikeRepair')}
                onmouseout={(e)=>onChangeOutNew(e,'repair','playaBikeRepair')}
                onclick={()=>openModalInfo('playaBikeRepair')}
              //  tap={()=>openModalInfo('playaBikeRepair')}

                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('playaBikeRepair')}
               // filters={checks.includes('playaBikeRepair') ? []:[contrastFilterEx]}
            />

            <Sprite
                ref={time3Ref}
                alpha={defaultAlpha}
                x={calculateSize(2995)}
                y={calculateSize(1545)}
                width={calculateSize(320)}
                height={calculateSize(461)}
                texture={currentKey ? (checks.includes('streetNamesOrientation') ? time3Sprite[0] : time3Sprite[1]):time3Sprite[0]}
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'time3','streetNamesOrientation')}
                onmouseout={(e)=>onChangeOutNew(e,'time3','streetNamesOrientation')}
                onclick={()=>openModalInfo('streetNamesOrientation')}
                //tap={()=>openModalInfo('streetNamesOrientation')}

                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('streetNamesOrientation')}
             //   filters={checks.includes('streetNamesOrientation') ? []:[contrastFilterEx]}
            />

            <Sprite
                ref={time9Ref}
                alpha={defaultAlpha}
                x={calculateSize(1855)}
                y={calculateSize(950)}
                width={calculateSize(312)}
                height={calculateSize(495)}//
                texture={currentKey ? (checks.includes('streetNamesOrientation') ? time9Sprite[0] : time9Sprite[1]):time9Sprite[0]}
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'time9','streetNamesOrientation')}
                onmouseout={(e)=>onChangeOutNew(e,'time9','streetNamesOrientation')}
                onclick={()=>openModalInfo('streetNamesOrientation')}
              //  tap={()=>openModalInfo('streetNamesOrientation')}
                hitArea={ width < 1200 ? new Rectangle(50, 50, 312/2.5, 495/2.5) : null} // hitArea={new Rectangle(0, 0, 600, 1078)}
                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('streetNamesOrientation')}
               // filters={checks.includes('streetNamesOrientation') ? []:[contrastFilterEx]}
            />


            <Sprite
                ref={ebikesRef}
                alpha={defaultAlpha}
                anchor={{x: 0.5, y: 0.5}}
                x={calculateSize(2055)}
                y={calculateSize(1450)}
                width={calculateSize(288)}
                height={calculateSize(288)}
                texture={currentKey ? (checks.includes('eBikeRules') ? ebikesSprite[0] : ebikesSprite[1]):ebikesSprite[0]}
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'ebikes','eBikeRules')}
                onmouseout={(e)=>onChangeOutNew(e,'ebikes','eBikeRules')}
                onclick={()=>openModalInfo('eBikeRules')}
             //   tap={()=>openModalInfo('eBikeRules')}

                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('eBikeRules')}
              //  filters={checks.includes('eBikeRules') ? []:[contrastFilterEx]}
            />
            <Sprite
                ref={bikesRef}
                alpha={defaultAlpha}
                anchor={{x: 0.5, y: 0.5}}
                x={height < 800 || width < 1200 ?  calculateSize(4475): calculateSize(3775)}
                y={height < 800 || width < 1200 ?  calculateSize(720): calculateSize(280)}
                width={calculateSize(226)}
                height={calculateSize(334)}
                texture={currentKey ? (checks.includes('bikeRules') ? bikesSprite[0] : bikesSprite[1]):bikesSprite[0]}
                interactive={true}
                onmouseover={(e)=>onChangeOverNew(e,'bikes','bikeRules')}
                onmouseout={(e)=>onChangeOutNew(e,'bikes','bikeRules')}
                onclick={()=>openModalInfo('bikeRules')}
              //  tap={()=>openModalInfo('bikeRules')}

                ontouchstart={onTouchStart}
                ontouchend={()=>openModalInfo('bikeRules')}
              //  filters={checks.includes('bikeRules') ? []:[contrastFilterEx]}
            />
        </>
    );
});

export default SecondItems;