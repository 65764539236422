import {FC, ReactNode} from 'react';
import './ModalInfo.scss'

interface IModalInfo{
    title?: ReactNode;
    children?:  ReactNode;
    buttons?: ReactNode;
}
const ModalInfo:FC<IModalInfo> = ({ title,children,buttons}) => {
    return (
        <div className={'modal-info'}>
            <div className="modal-info__inner">
                <div className="modal-info__title">{title}</div>
                <div className="modal-info__content">
                    {children}
                </div>
                <div className="modal-info__buttons">
                    {buttons}
                </div>
            </div>
        </div>
    );
};

export default ModalInfo;