import  {FC} from 'react';
import Modal from "../Modal/Modal";
import ModalSingle from "../ModalContent/ModalSingle/ModalSingle";
import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";
import {
    changeModalClans,
    changeModalInfo,
    changeModalInfoCurrent,
    changeModalSecondKey
} from "../../../redux/slices/modalSlice/modalSlice";

import tempe from "../../../assets/img/second_items/modal/tempe.png";
import ring_men from "../../../assets/img/second_items/modal/ring-men.png";
import time_3 from "../../../assets/img/second_items/modal/time-3.png";
import main_camp from "../../../assets/img/second_items/modal/main_camp.png";
import airport from "../../../assets/img/second_items/modal/airport.png";
import mailbox from "../../../assets/img/second_items/modal/mailbox.png";
import medic from "../../../assets/img/second_items/modal/medic.png";
import ice from "../../../assets/img/second_items/modal/ice.png";
import repair_bycicle from "../../../assets/img/second_items/modal/repair_bycicle.png";
import cakes from "../../../assets/img/second_items/modal/cakes.png";
import dancers from "../../../assets/img/dance.png";
import fireman from "../../../assets/img/main_items/fireman_low.png";
import bunny from "../../../assets/img/second_items/modal/bunny.png";

import ebikes from "../../../assets/img/second_items/modal/ebikes.png";
import bikes from "../../../assets/img/second_items/modal/bikes.png";
import {changeCurrentKeysCounter, changeDisabledCanvasHover} from "../../../redux/slices/appSlice";
import { changeCheckInfo } from '../../../redux/slices/checkSlice/checkSlice';
import Button from "../../atoms/Button/Button";

const modalInfoData: { [key: string]: {img?:string; title: string; content: string } } = {
    entranceGate: {
        img: ring_men,
        title: "Entrance Gate",
        content: "Your Burn starts at the entrance gate, where greeters will meet you, point you in the direction of your camp and maybe have you lay down in the dust to make the playa version of a snow angel."
    },
    streetNamesOrientation: {
        img: time_3,
        title: "Street Names + Orientation",
        content: "Black Rock City is designed as an easy to navigate grid. Names of the long streets in a semi-circle shape all begin with letters and run alphabetically. (In 2017, for example, street names were “Awe” “Breath” “Ceremony” etc.) Streets in the other direction are named for times and run like a clock, with the 2:00 street on one side of the City and 10:00 on the other."
    },
    esplanade: {
        img: dancers,
        title: "Esplanade",
        content: "Party central! The innermost ring of the city, the Esplanade is where you’ll find many theme camps, festivities and fun diversions like rollerskating and swings."
    },
    deepPlaya: {
        img: cakes,
        title: "Deep Playa",
        content: "Deep playa is the expanse of desert beyond the city. This is an incredible place to roam by day or night. (Make sure you bring the necessary supplies like water and lights.) Here you’ll find many art installations and art car parties. This is also where the wedding will be, with exact coordinates provided closer to the August event. "
    },
    centerCamp: {
        img: main_camp,
        title: "Center Camp",
        content: "Center Camp is the hub of Black Rock City, located in the middle of the gridded streets and a place where you'll find art, performances, information officers and other programming. (Details about programming here and at other camps will be available in the guidebook provided to you upon entry at the Gate.)"
    },
    temple: {
        img: tempe,
        title: "Temple",
        content: "The visit to the Temple is essential to the Burning Man experience. Here, Burners leave mementos of those who’ve passed on and tributes to other heartaches in life moments. A sacred place of reflection, the Temple is burned on the final evening of Burning Man."
    },
    theMan: {
        img: fireman,
        title: "The Man",
        content: "The gathering’s namesake structure, the man is located at the center of the playa and is burned during an always festive Saturday night party that draws art cars, fire spinners and other revelry."
    },
    artCars: {
        img: bunny,
        title: "Art Cars",
        content: "Art cars, or mutant vehicles, are cars, buses, golf carts and other forms of transportation that have been tricked out with fantastical designs. Many art cars offer music, and many will welcome you aboard for a cruise across the play."
    },
    airport: {
        img:airport,
        title: "Airport",
        content: "Burning Man has its own airport! The Black Rock City Airport welcomes chartered planes bringing Burners in and out of the city. "
    },
    postOffice: {
        img: mailbox,
        title: "Post Office",
        content: "Let your friends and family know how much fun you’re having by sending them a postcard from the playa’s very own post office, typically located near Center Camp. (Heads up: the U.S. Postal Service, FedEx and UPS do not deliver to the playa.)"
    },
    playaBikeRepair: {
        img: repair_bycicle,
        title: "Playa Bike Repair",
        content: "If you’re riding a bike at Burning Man, make sure it arrives there in tip top shape. That said, flat tires and broken chains happen. Several camps offer bike repair. Check your guidebook to locate them."
    },
    medicalTents: {
        img: medic,
        title: "Medical Tents",
        content: "Burning Man has extensive medical services. If you experience an illness or injury that’s more severe than you can handle yourself or in camp, emergency medical stations are located at the 3:00+C and 9:00+C plazas, and on the Esplanade at 5:15. All stations are identifiable by a red cross on top of the buildings and staffed by doctors, nurses and medics who donate their time and expertise."
    },
    bikeRules: {
        img: bikes,
        title: "Bike Rules",
        content: "To keep the playa safe and free from clutter, it’s important to leave (and lock!) your bike at designated areas, which are located at nearly all camps and installations."
    },
    eBikeRules: {
        img: ebikes,
        title: "E-Bike Rules",
        content: "E-bikes are fun, but they’re also fast. To maintain safety in Black Rock City, it’s important to abide by the 5 mph rule."
    },
    arctica: {
        img: ice,
        title: "Arctica",
        content: "There’s only one item exchanged for cash in Black Rock City: ice! Bags of ice are available at the Arctica Camp, located at 3 o’clock, 9 o’clock and Center Camp, during designated hours. A 16 pound bag of ice costs $10 and a 10 pound bag costs $5."
    }
};
const ModalInformation:FC = () => {
    const {modalInfo,modalInfoCurrent} = useSelectorEx(state=>state.modals);
    const dispatch = useDispatchEx();
    const currentClan = localStorage.getItem('currentClans') ? JSON.parse(localStorage.getItem('currentClans') as string) : null

   const onCloseEx = (type?:string)=>{
       const checkInfo = localStorage.getItem('checkInfo');
       const oldCheck = checkInfo?.split(',');

       if(!oldCheck || !oldCheck.includes(modalInfoCurrent)){
           const newValue = checkInfo ? [...checkInfo.split(','),modalInfoCurrent] : [modalInfoCurrent];
           dispatch(changeCheckInfo(newValue));
           localStorage.setItem('checkInfo', newValue.join(','));
       }
       dispatch(changeModalInfo(false))
       dispatch(changeModalInfoCurrent(""));
       if(type === 'button'){
           dispatch(changeDisabledCanvasHover(true));
       }
   }
    return (
    <Modal
        back={true}
        active={modalInfo}
        onClose={onCloseEx}
    >
        <ModalSingle
            title={modalInfoData[modalInfoCurrent]?.title}
            img={modalInfoData[modalInfoCurrent]?.img}
            buttons={<>
                <Button onClick={()=>onCloseEx('button')} name={'Continue'} className="button--primary" />
            </>}
        >
            {modalInfoData[modalInfoCurrent]?.content}
        </ModalSingle>

    </Modal>
    );
};

export default ModalInformation;