import {FC, useState} from 'react';
import ModalSingle from "../ModalContent/ModalSingle/ModalSingle";
import img1 from "../../../assets/img/artcar.jpg";
import Button from "../../atoms/Button/Button";
import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";
import {changeModalArtCar, changeModalMeals, changeModalPrinciples} from "../../../redux/slices/modalSlice/modalSlice";
import {changeDisabledCanvasHover} from "../../../redux/slices/appSlice";
import Modal from "../Modal/Modal";
import {changeCheckInfo} from "../../../redux/slices/checkSlice/checkSlice";

const ModalArtCar:FC = () => {
    const dispatch = useDispatchEx();
    const {modalArtCar} = useSelectorEx(state => state.modals);

    const onClose = ()=>{
        dispatch(changeModalArtCar(false));
        const checkInfo = localStorage.getItem('checkInfo');
        const oldCheck = checkInfo?.split(',');

        if(!oldCheck || !oldCheck.includes("longFengCar")){
            const newValue = checkInfo ? [...checkInfo.split(','),"longFengCar"] : ["longFengCar"];
            dispatch(changeCheckInfo(newValue));
            localStorage.setItem('checkInfo', newValue.join(','));
        }

        dispatch(changeDisabledCanvasHover(true))

    }
    return (
        <Modal
            back={true}
            active={modalArtCar}
            onClose={onClose}
        >
            <ModalSingle
                active={modalArtCar}
                title={<>Long Feng (Art Car)</>}
                img={img1}
                buttons={
                    <>
                        <Button type={'htmlLink'} href={'https://www.paypal.com/donate?campaign_id=WR2TDQRSMWCAQ'} className={'button--secondary'} name={'Donate'} />
                        <Button onClick={onClose} className={'button--primary'} name={'Continue'} />
                    </>
                }
            >
                <p>Rising from the ashes of birth, culminating as a manifestation of our love, and in celebration of bringing our closest friends and family together, we are pleased to announce the conception of our camp’s mutant vehicle, Long Feng!</p>

                <p>Symbolizing the union of the Dragon & the Phoenix, Long Feng pays tribute to our wedding and to the Chinese year of the Dragon.</p>

                <p>At its core, Long Feng is an homage to the intricate articulation of a contortionist, mirroring the circus performer's ability to transform and transcend. Just as the human form can contort and adapt, so too can this vehicle, bending the boundaries of what is possible, offering a performance that captivates and inspires.</p>

                <p>Long Feng is a living, breathing testament to the boundless creativity and limitless possibilities of the human spirit, now embodied in the form of a dragon, a creature that embodies mythical and artistic expression in its most powerful and transformative way.</p>

                <p>We are so excited to share Long Feng with you all. This year, we will not be hosting a registry for our wedding. But for anyone feeling called to contribute to the project, we are immensely grateful. It is going to be epic!</p>

              {/*  <p><span>*For a tax deductible donation, please reach out to patty.natili@gmail.com</span></p>*/}

            </ModalSingle>
        </Modal>
    );
};

export default ModalArtCar;