import {FC} from 'react';

const CloseIcon:FC = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 40L0 36L16 20L0 4L4 0L20 16L36 0L40 4L24 20L40 36L36 40L20 24L4 40Z" fill="#946051" fillOpacity="0.5"/>
        </svg>
    );
};

export default CloseIcon;