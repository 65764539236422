import './GlobalMap.scss'
import {FC, useEffect, useRef} from 'react';
import * as PIXI from "pixi.js";

import {Stage, Container, Sprite} from "@pixi/react";
import useCalculateSize from "../../../hooks/useCalculateSize";
import { gsap } from "gsap";
import { PixiPlugin } from "gsap/dist/PixiPlugin";
import map from '../../../assets/img/background_small.jpg'
import useWindowSize from "../../../hooks/useWindowSize";


import hide from "../../../assets/img/hide.png";

import section_left_firstBig from "../../../assets/img/sections/left_first.png";
import section_left_secondBig from "../../../assets/img/sections/left_second.png";
import section_right_firstBig from "../../../assets/img/sections/right_first.png";
import section_right_secondBig from "../../../assets/img/sections/right_second.png";


import section_left_firstNormal from "../../../assets/img/sections/normal/left_first.png";
import section_left_secondNormal from "../../../assets/img/sections/normal/left_second.png";
import section_right_firstNormal from "../../../assets/img/sections/normal/right_first.png";
import section_right_secondNormal from "../../../assets/img/sections/normal/right_second.png";

import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";

import SecondItems from "../SecondItems/SecondItems";
import AnimationsItems from "../AnimationsItems/AnimationsItems";
import MainItems from "../MainItems/MainItems";
import NightMode from "../NightMode/NightMode";
import {animationFinish, animationStart} from "../../../helpers/animations";
import FireAnimation from "../FireAnimation/FireAnimation";
import DancersItems from "../SecondItems/DancersItems";
import {CLEAR_MODES} from "@pixi/constants";


gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);


const imagesSizesList = {
    2600:[section_left_firstNormal,section_left_secondNormal,section_right_firstNormal,section_right_secondNormal],
    3800:[section_left_firstBig,section_left_secondBig,section_right_firstBig,section_right_secondBig]
}
const [section_left_first,
    section_left_second,
    section_right_first,
    section_right_second] = window.innerWidth > 2600 ? imagesSizesList[3800]: imagesSizesList[2600];


const GlobalMap:FC =({}) => {
  /*  PIXI.settings.RESOLUTION = window.devicePixelRatio;
    PIXI.settings.MIPMAP_TEXTURES = PIXI.MIPMAP_MODES.OFF;
    PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST*/
   // PIXI.settings.CLEAR_MODES = PIXI.CLEAR_MODES.YES

    const calculateSize = useCalculateSize();
    const {width,height} = useWindowSize();
    const {modalNight} = useSelectorEx(state => state.modals)
    const {firstEmail,mapLoading,loadedApp,disabledCanvasHover,currentKeys,isOpenKeyModal} = useSelectorEx(state => state.app)
    const {checkAccommodation,
        checkClans,
        checkGetAround,
        checkGetThere,
        checkMeal,
        checkBurningMan,
        checkInfo
    } = useSelectorEx(state => state.checks);

    const isLoaded = useRef<any>();

    const refStage = useRef<any>(null);
    const dispatch = useDispatchEx();


    const dispatchEx = (callback:any)=>{
        dispatch(callback);
    }

    const sectionLeft1Ref = useRef<any>();
    const sectionLeft2Ref = useRef<any>();
    const sectionRight1Ref = useRef<any>();
    const sectionRight2Ref = useRef<any>();

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
        const needEmail = localStorage.getItem('burningEmail') ? localStorage.getItem('burningEmail') : firstEmail;
        if( mapLoading && loadedApp && !isLoaded.current && needEmail){
            timeoutId=setTimeout(() => {
                const timeline = gsap.timeline();
                timeline.fromTo(sectionLeft1Ref.current, animationStart(sectionLeft1Ref.current),animationFinish(sectionLeft1Ref.current),0.3);
                timeline.fromTo(sectionLeft2Ref.current, animationStart(sectionLeft2Ref.current),animationFinish(sectionLeft2Ref.current),0.3);
                timeline.fromTo(sectionRight1Ref.current, animationStart(sectionRight1Ref.current),animationFinish(sectionRight1Ref.current),0.3);
                timeline.fromTo(sectionRight2Ref.current, animationStart(sectionRight2Ref.current),animationFinish(sectionRight2Ref.current),0.3);
                isLoaded.current = true;
            },1000)
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [mapLoading,width,height,loadedApp,firstEmail]);

    const firstStageRef = useRef<any>();

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
        if( mapLoading && loadedApp && currentKeys !== 0 && firstStageRef.current ){
            timeoutId=setTimeout(() => {
                const timeline = gsap.timeline();
                timeline.fromTo(firstStageRef.current, {
                    pixi:{
                        alpha:1
                    }
            },{
                    pixi:{
                        alpha:0
                    }
                },0.3);

            },2000)
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [mapLoading,loadedApp,currentKeys]);
    useEffect(() => {
        if( mapLoading && loadedApp && currentKeys !== 0){

            gsap.to(firstStageRef.current,{
                pixi: {
                    alpha: 0,
                },
                duration: 0.7
            });
        }
    }, [width]);

    useEffect(()=>{
        if(refStage.current){
      //      console.log('refStage',refStage.current)
            // @ts-ignore
         //   globalThis.__PIXI_APP__ = refStage.current.app;
        }
    },[refStage])
    function visibleAll(){

        const alphaAnimate = {
            pixi:{
                alpha:1
            }
        }
        const allRefs = [sectionLeft1Ref,
            sectionLeft2Ref,
            sectionRight1Ref,
            sectionRight2Ref,

        ];
        const timelineEx = gsap.timeline();
        allRefs.forEach((ref,index) => {
            if (ref.current) {
                gsap.set(ref.current, alphaAnimate);
            }
        });
    }

    useEffect(() => {
        if( mapLoading && loadedApp && isLoaded.current) {
            visibleAll();
        }
    }, [mapLoading,loadedApp,width,height]);




    return (
                <Stage ref={refStage}

                        options={{
                            clearBeforeRender: true,
                            resolution: window.devicePixelRatio || 1,
                            antialias: true,
                            autoDensity: true,
                        }}//2760 1513
                         style={{transform: "translate(0%,0%)",imageResolution: "from-image 300dpi"}} width={width < 1200 ? 1380 : calculateSize(5760)} height={width < 1200 ? 756 : calculateSize(3240)} className={'global-map__canvas'}>
{/*                    <Graphics

                        draw={(g) => {
                            g.beginFill(0xc0b4a9); // Цвет фона в формате шестнадцатеричного числа
                            g.drawRect(0, 0, 5760, 5760);
                            g.endFill();
                        }}
                    />*/}

                    <Container >

                       <Container zIndex={1}>

                        {/*   <Sprite
                               width={5760}
                               height={3240}
                               image={map}
                           />*/}
                           <Sprite
                                width={calculateSize(5760)}
                                height={calculateSize(3240)}
                                image={map}
                            />

                        </Container>

                        <Container sortableChildren={true} zIndex={1}>
                           <Sprite
                                zIndex={-1}
                                ref={sectionLeft1Ref}
                                alpha={0}
                                x={calculateSize(663)}//calculateSize(2263)
                                y={calculateSize(777)}//calculateSize(767)
                                width={calculateSize(1313)}
                                height={calculateSize(1573)}
                                image={section_left_second}
                                interactive={true}
                            />

                            <Sprite
                                zIndex={-1}
                                ref={sectionLeft2Ref}
                                alpha={0}
                                x={calculateSize(1433)}
                                y={calculateSize(347)}
                                width={calculateSize(1304)}
                                height={calculateSize(785)}
                                image={section_left_first}
                                interactive={true}
                            />
                            <Sprite
                                zIndex={0}
                                ref={sectionRight1Ref}
                                alpha={0}
                                x={calculateSize(1303)}
                                y={calculateSize(1907)}
                                width={calculateSize(2662)}
                                height={calculateSize(951)}
                                image={section_right_first}
                                interactive={true}

                            />
                            <Sprite
                                ref={sectionRight2Ref}
                                alpha={0}
                                x={calculateSize(3333)}
                                y={calculateSize(1420)}
                                width={calculateSize(1338)}
                                height={calculateSize(836)}
                                image={section_right_second}
                                interactive={true}
                                zIndex={0}
                            />

                            <SecondItems
                                isOpenKeyModal={isOpenKeyModal}
                                firstEmail={firstEmail}
                                currentKey={currentKeys}
                                checks={checkInfo}
                                dispatch={dispatchEx}
                                interactive={disabledCanvasHover}
                                mapLoading={mapLoading}
                                loadedApp={loadedApp}
                            />
                            <DancersItems
                                firstEmail={firstEmail}
                                mapLoading={mapLoading}
                                loadedApp={loadedApp}

                            />
                            <Sprite
                                ref={firstStageRef}
                                zIndex={0}
                                alpha={1}
                                width={calculateSize(5760)}
                                height={calculateSize(3240)}
                                x={0}
                                y={0}
                                image={hide}
                            />
                           <MainItems
                               firstEmail={firstEmail}
                               currentKey={currentKeys}
                               dispatch={dispatchEx}
                               interactive={disabledCanvasHover}
                               mapLoading={mapLoading}
                               loadedApp={loadedApp}
                               checks={{checkAccommodation,checkClans,checkGetAround,checkGetThere,checkMeal,checkBurningMan,checkInfo}}
                           />

                            <AnimationsItems
                                firstEmail={firstEmail}
                                mapLoading={mapLoading}
                                loadedApp={loadedApp}
                            />
                        </Container>
                        {!modalNight && <FireAnimation
                            currentKey={currentKeys}
                            dispatch={dispatch}
                            mapLoading={mapLoading}
                            loadedApp={loadedApp}
                        />}
                        {!modalNight && <NightMode
                            currentKey={currentKeys}
                            dispatch={dispatch}
                            mapLoading={mapLoading}
                            loadedApp={loadedApp}
                        />}

                    </Container>

                </Stage>
    );
};

export default GlobalMap;