import {useState} from 'react';
import Modal from "../Modal/Modal";

import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";

import {changeModalBurningMan, changeModalFirstKey} from "../../../redux/slices/modalSlice/modalSlice";
import ModalExperience from "../ModalContent/ModalExperience/ModalExperience";
import {changeCurrentKeys, changeDisabledCanvasHover, changeIsOpenKeyModal} from "../../../redux/slices/appSlice";
import {changeCheckBurningMan, changeMailSend} from "../../../redux/slices/checkSlice/checkSlice";


const ModalBurningMan = () => {
    const dispatch = useDispatchEx();
    const {modalBurningMan} = useSelectorEx(state => state.modals);
    const {isMailSend} = useSelectorEx(state => state.checks);
    const onClose = ()=>{
        const sentMail = localStorage.getItem('sentMail') ? localStorage.getItem('sentMail'): false;
        if(!sentMail){
            dispatch(changeDisabledCanvasHover(true));
        }
        else{
            const keysNumber =  localStorage.getItem('keysNumber');
            if(!keysNumber){
                dispatch(changeModalFirstKey(true))
                dispatch(changeCurrentKeys(1));
                localStorage.setItem('keysNumber',"1");
                dispatch(changeCheckBurningMan(true))
                dispatch(changeMailSend(true))
                dispatch(changeIsOpenKeyModal(true))
            }



        }
        dispatch(changeModalBurningMan(false));

    }


    return (
        <>
            <Modal
                back={true}
                active={modalBurningMan}
                onClose={onClose}
            >
                <ModalExperience active={modalBurningMan}/>

            </Modal>
        </>
    );
};

export default ModalBurningMan;