import React, {FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {AppProvider} from "@pixi/react";
import {Application, ICanvas, Rectangle, Texture} from "pixi.js";
import GlobalMap from "../molecules/GlobalMap/GlobalMap";

import {loadImages} from "../../helpers/scripts";

import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {
    changeCurrentKeys, changeFirstEmail,
    changeIsTappedTitle,
    changeLoadedApp,
    changeMapLoading, changeModalIntro, changeModalIntroBack,
    changePreloaderPercent
} from "../../redux/slices/appSlice";
import Preloader from "../atoms/Preloader/Preloader";

import preloder_img from "../../assets/img/preloader.png";
import preloder_large from "../../assets/img/preloader_large.png";
import preloder_back from "../../assets/img/back2.jpg";

import clans from "../../assets/img/main_items/clans_low.png";
import accomodations from "../../assets/img/main_items/accomodations_low.png";
import fireman from "../../assets/img/main_items/fireman_low.png";
import mapBig from '../../assets/img/background.jpg'
import mapNormal from '../../assets/img/background_small.jpg'
import cars from "../../assets/img/main_items/cars_low.png";
import mobility from "../../assets/img/main_items/mobility_low.png";
import meal from "../../assets/img/main_items/meal_low.png";


import clansNormal from "../../assets/img/main_items/normal/clans_low.png";
import accomodationsNormal from "../../assets/img/main_items/normal/accomodations_low.png";
import firemanNormal from "../../assets/img/main_items/normal/fireman_low.png";
//import mapNormal from '../../assets/img/background.jpg'
import carsNormal from "../../assets/img/main_items/normal/cars_low.png";
import mobilityNormal from "../../assets/img/main_items/normal/mobility_low.png";
import mealNormal from "../../assets/img/main_items/normal/meal_low.png";



import section_left_firstBig from "../../assets/img/sections/left_first.png";
import section_left_secondBig from "../../assets/img/sections/left_second.png";
import section_right_firstBig from "../../assets/img/sections/right_first.png";
import section_right_secondBig from "../../assets/img/sections/right_second.png";


import section_left_firstNormal from "../../assets/img/sections/normal/left_first.png";
import section_left_secondNormal from "../../assets/img/sections/normal/left_second.png";
import section_right_firstNormal from "../../assets/img/sections/normal/right_first.png";
import section_right_secondNormal from "../../assets/img/sections/normal/right_second.png";

import all_bikes from "../../assets/img/animation/all_bikes.png";


// First Type: Original Path with "Big" suffix
import ring_menBig from "../../assets/img/second_items/ring-men.png";
import airportBig from "../../assets/img/second_items/airport.png";
import airplaneBig from "../../assets/img/second_items/airplane.png";
import scrollBig from "../../assets/img/second_items/scroll.png";
import tempeBig from "../../assets/img/second_items/tempe.png";
import artcarBig from "../../assets/img/second_items/artcar.png";
import bunnyBig from "../../assets/img/second_items/bunny.png";
import cakesBig from "../../assets/img/second_items/cakes.png";
import iceBig from "../../assets/img/second_items/ice.png";
import mailboxBig from "../../assets/img/second_items/mailbox.png";
import repair_bycicleBig from "../../assets/img/second_items/repair_bycicle.png";
import medicBig from "../../assets/img/second_items/medic.png";
import time_3Big from "../../assets/img/second_items/time-3.png";
import time_9Big from "../../assets/img/second_items/time-9.png";
import dancers from "../../assets/img/second_items/dancers.png";
import ebikesBig from "../../assets/img/second_items/ebikes.png";
import bikesBig from "../../assets/img/second_items/bikes.png";
import main_campBig from "../../assets/img/second_items/main_camp.png";

// Second Type: Modified Path with "Normal" suffix
import ring_menNormal from "../../assets/img/second_items/normal/ring-men.png";
import airportNormal from "../../assets/img/second_items/normal/airport.png";
import airplaneNormal from "../../assets/img/second_items/normal/airplane.png";
import scrollNormal from "../../assets/img/second_items/normal/scroll.png";
import tempeNormal from "../../assets/img/second_items/normal/tempe.png";
import artcarNormal from "../../assets/img/second_items/normal/artcar.png";
import bunnyNormal from "../../assets/img/second_items/normal/bunny.png";
import cakesNormal from "../../assets/img/second_items/normal/cakes.png";
import iceNormal from "../../assets/img/second_items/normal/ice.png";
import mailboxNormal from "../../assets/img/second_items/normal/mailbox.png";
import repair_bycicleNormal from "../../assets/img/second_items/normal/repair_bycicle.png";
import medicNormal from "../../assets/img/second_items/normal/medic.png";
import time_3Normal from "../../assets/img/second_items/normal/time-3.png";
import time_9Normal from "../../assets/img/second_items/normal/time-9.png";
import ebikesNormal from "../../assets/img/second_items/normal/ebikes.png";
import bikesNormal from "../../assets/img/second_items/normal/bikes.png";
import main_campNormal from "../../assets/img/second_items/normal/main_camp.png";

import dragon from "../../assets/img/animation/dragon.png";
import phenix from "../../assets/img/animation/phenix.png";
import cloud_1 from "../../assets/img/animation/cloud_1.png";
import cloud_2 from "../../assets/img/animation/cloud_2.png";
//import cloud_3 from "../../assets/img/animation/cloud_3.png";



import night from "../../assets/img/night.png";
import light_1 from "../../assets/img/light_1.png";
import light_2 from "../../assets/img/light_2.png";
import fires from "../../assets/img/fire/fires.png";

import useWindowSize from "../../hooks/useWindowSize";
import MobileControls from "../atoms/MobileControls/MobileControls";
import GetTicket from "../molecules/GetTicket/GetTicket";
import KeysPanel from "../molecules/KeysPanel/KeysPanel";

import Modals from "../organisms/Modals";
import {gsap} from "gsap";
import {delay} from "@reduxjs/toolkit/dist/utils";
import {
    changeCheckAccommodation,
    changeCheckBurningMan,
    changeCheckClans, changeCheckGetAround, changeCheckGetThere, changeCheckMeals, changeMailSend
} from "../../redux/slices/checkSlice/checkSlice";
import {changeModalTutorial} from "../../redux/slices/modalSlice/modalSlice";


const imagesSecondSizesList = {
    2600:[
        ring_menNormal,
        airportNormal,
        airplaneNormal,
        scrollNormal,
        tempeNormal,
        artcarNormal,
        bunnyNormal,
        cakesNormal,
        iceNormal,
        mailboxNormal,
        repair_bycicleNormal,
        medicNormal,
        time_3Normal,
        time_9Normal,
        ebikesNormal,
        bikesNormal,
        main_campNormal
    ],
    3800:[
        ring_menBig,
        airportBig,
        airplaneBig,
        scrollBig,
        tempeBig,
        artcarBig,
        bunnyBig,
        cakesBig,
        iceBig,
        mailboxBig,
        repair_bycicleBig,
        medicBig,
        time_3Big,
        time_9Big,
        ebikesBig,
        bikesBig,
        main_campBig
    ]
}
const [
    ring_men,
    airport,
    airplane,
    scroll,
    tempe,
    artcar,
    bunny,
    cakes,
    ice,
    mailbox,
    repair_bycicle,
    medic,
    time_3,
    time_9,
    ebikes,
    bikes,
    main_camp
] = window.innerWidth > 2600 ? imagesSecondSizesList[3800]: imagesSecondSizesList[2600];


const imagesSizesList = {
    2600:[section_left_firstNormal,section_left_secondNormal,section_right_firstNormal,section_right_secondNormal],
    3800:[section_left_firstBig,section_left_secondBig,section_right_firstBig,section_right_secondBig]
}
const [section_left_first,
    section_left_second,
    section_right_first,
    section_right_second] = window.innerWidth > 2600 ? imagesSizesList[3800]: imagesSizesList[2600];

const GlobalMapContainer:FC = () => {
    const appRef = useRef<any>(null);
    const { width,height } = useWindowSize();
    const dispatch = useDispatchEx();
    const mapLoading = useSelectorEx(state => state.app.mapLoading)

    useEffect(() => {
        if (!appRef.current) {
         //   appRef.current = new Application<ICanvas>();

            // @ts-ignore
            //globalThis.__PIXI_APP__ = appRef.current;
            dispatch(changeLoadedApp(true))
            // Дополнительная инициализация, если она требуется
        }

        return () => {
            if (appRef.current) {
                // Очистить контекст или выполнить другие действия по очистке
                appRef.current = null;
            }
        };
    }, []);
    const {modalLoves} = useSelectorEx(state => state.modals)
    const {loadedApp,currentKeys,isTappedTitle,firstEmail} = useSelectorEx(state => state.app)

    useEffect(() => {
        if(loadedApp){
            let mainImages = [];
            let mainItems = [];
            let sectionsItems = [];
            let secondItems = [];
            let animationsItems = [];
            let nightItems = [night,light_1,light_2,fires];
            if(window.innerWidth > 1920){
                mainImages = [preloder_back,preloder_large];
            }
            else{
                mainImages = [preloder_back,preloder_img];
            }
            if(window.innerWidth > 2600){
                mainItems = [preloder_back,preloder_img,fireman,mapBig,accomodations,clans,cars,mobility,meal];
            }
            else{
                mainItems = [preloder_back,preloder_img,firemanNormal,mapNormal,accomodationsNormal,clansNormal,carsNormal,mobilityNormal,mealNormal];
            }
            //mainItems = [preloder_back,preloder_img,fireman,map,accomodations,clans,cars,mobility,meal];
            sectionsItems = [section_left_first,section_left_second,section_right_first,section_right_second,dancers];
            secondItems = [ring_men, airport, airplane, scroll, tempe, artcar, bunny, cakes, ice, mailbox, repair_bycicle, medic, time_3, time_9,main_camp];
            animationsItems = [dragon,phenix,cloud_1,cloud_2,all_bikes];

            const allImages = [
                ...mainImages,
                ...mainItems,
                ...sectionsItems,
                ...secondItems,
                ...animationsItems,
                ...nightItems

            ];
            let countImages = 0;
            loadImages(allImages,(image) => {
                countImages += 1;
                dispatch(changePreloaderPercent(countImages / (allImages.length / 100)));
               // console.log('Image loaded:', image.src);
            }).then(()=>{
                setTimeout(()=>{
                    dispatch(changeMapLoading(true))
                },2000)

                /* setIsImageLoaded(true);*/
                console.log('loaded images')
            })
        }

    }, [loadedApp]);


    const globalMapRef = useRef<HTMLDivElement>(null);
    const isInLeftZoneRef = useRef(false);
    const isInRightZoneRef = useRef(false);
    const isInUpZoneRef = useRef(false);
    const isInDownZoneRef = useRef(false);


    const touchStartPosition = useRef({x:0,y:0});
    const isTouchDevice = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    };

    const handleMouseMove = useCallback((e: MouseEvent) => {
        if(modalLoves) return;
        const globalMapElement = document.querySelector('.global-map__canvas') as HTMLElement//globalMapRef.current;
        if (!globalMapElement) return;

        const mouseX = e.clientX;
        const mouseY = e.clientY;

        const leftBoundary = window.innerWidth * 0.20;
        const rightBoundary = window.innerWidth * 0.80;

        const topBoundary = window.innerHeight * 0.20;
        const downBoundary = window.innerHeight * 0.80;

        isInLeftZoneRef.current = mouseX < leftBoundary;
        isInRightZoneRef.current = mouseX > rightBoundary;

        isInUpZoneRef.current = mouseY < topBoundary;
        isInDownZoneRef.current = mouseY > downBoundary;

    },[modalLoves]);

    const handleTouchMove = useCallback((e: TouchEvent) => {
        if(modalLoves) return;
        const globalMapElement = document.querySelector('.global-map__canvas') as HTMLElement//globalMapRef.current;
        if (!globalMapElement) return;
        const touch = e.touches[0];

        const touchX = touch.clientX;
        const touchY = touch.clientY;

        const newX = touchStartPosition.current.x - touch.clientX;
        const newY = touchStartPosition.current.y - touch.clientY;
        // console.log('touchEnd',touchX,touchY)

       // console.log('newXnewY', newX,newY)
        const transformValue = globalMapElement.style.transform;
        // const regex = /translate\((-?\d*\.?\d+)%, (-?\d*\.?\d+)%\)/;
        const regex = /translate\((-?\d*\.?\d+)px, (-?\d*\.?\d+)px\)/;
        const match = transformValue.match(regex);


        let oldPositionX = match ? parseFloat(match[1]) : 0;
        let oldPositionY = match ? parseFloat(match[2]) : 0;

     /*   const newValue = -2860 + window.innerWidth+100;
        const newValueY = -1613 + window.innerHeight+100;*/
        //160  280
        //300() 280 -480 86(280+1500-1100(view) = 300 start )

        //248 233 -430 135
      //  console.log('newPositionX',newPositionX,newPositionY)

        const scaleSize = 1.3


        //1932 1058
        const newValue = (1794 - window.innerWidth)/scaleSize ;
        const newValueY = (982 - window.innerHeight)/scaleSize;



        const newPositionX = oldPositionX - newX;
        const newPositionY = oldPositionY - newY;



        if(newPositionX > -newValue && newPositionX < 0 && newPositionY < 100 && newPositionY > -newValueY)//-1113
        {

            globalMapElement.style.transform = `translate(${newPositionX}px, ${newPositionY}px)`;
        }

/*        if (oldPositionX - newX < 0 && oldPositionY - newY < 0 && oldPositionX - newX < newValue && oldPositionY - newY < newValueY && oldPositionX - newX > -newValue && oldPositionY - newY > -newValueY) {

        }*/

        touchStartPosition.current = {
            x: touchX,
            y: touchY
        }
        if(isTappedTitle && currentKeys === 0){
            dispatch(changeIsTappedTitle(false))
        }
    },[width,height,touchStartPosition,isTappedTitle,currentKeys,modalLoves,dispatch]);



    useEffect(() => {
        if(touchStartPosition.current)
        {
            if(!isTappedTitle && currentKeys === 0){
                dispatch(changeIsTappedTitle(true))
            }
        }

    }, [touchStartPosition]);


    const handleTouchStart = useCallback((e: TouchEvent) => {
        if(modalLoves) return;
        const touch = e.touches[0];
      //  console.log('touchStart', touch)
        const touchX = touch.clientX;
        const touchY = touch.clientY;
        touchStartPosition.current = {
            x: touchX,
            y: touchY
        }
    },[modalLoves]);

    useEffect(() => {
        if(loadedApp){
            if (!isTouchDevice()) {
                if (width < 1200) {

                    if(currentKeys === 3){//-721.589px, -115.907px
                        const globalMapElement = document.querySelector('.global-map__canvas') as HTMLElement;
                        if (!globalMapElement) return;
/*                        const centerX = -1300 + window.innerWidth /2;
                        const centerY = -100 + window.innerWidth /2;*/

                        const centerX = (-1080 + window.innerWidth)/1.3 ;//(1345-1200) 1,7 1380
                        const centerY = (-768 + window.innerHeight)/1.3 ;
                       //globalMapElement.style.transform = `translate(${centerX}px, -215.907px)`;

                        globalMapElement.style.transform = `translate(${centerX}px, ${centerY}px)`;
                    }
/*                    else{



                    }*/
                    document.addEventListener('mousemove', handleMouseMove);

                    return () => {
                        document.removeEventListener('mousemove', handleMouseMove);
                    };
                }
            }
            else {
                document.addEventListener('touchstart', handleTouchStart);

                return () => {
                    document.removeEventListener('touchstart', handleTouchStart);
                };
            }
        }


    }, [loadedApp,width,height,currentKeys,handleTouchStart,handleMouseMove]);

    useEffect(() => {
        if(loadedApp) {
            const globalMapElement = document.querySelector('.global-map__canvas') as HTMLElement;
            const globalMapInnerElement = document.querySelector('.global-map__inner') as HTMLElement;
            if (width < 1200) {
                if(currentKeys === 3){//-721.589px, -115.907px

                    if (!globalMapElement) return;
/*                    const centerX = -1300 + window.innerWidth /2;
                    const centerY = -100 + window.innerWidth /2;*/
                    const scaleSize = 1.3      //1932 1058
                    const newValue = (1794 - window.innerWidth)/scaleSize ;
                    const rate = 1794/newValue;
                    const centerX =-750/rate ;//(1345-1200) 1100


                    const centerY = (-768 + window.innerHeight)/2 ;
                    //globalMapElement.style.transform = `translate(${centerX}px, -215.907px)`;


                   // globalMapElement.style.transform = `translate(${centerX}px,  ${centerY}px)`;
                }
            /*    else{

                }*/
                document.addEventListener('touchmove', handleTouchMove);
                return () => {
                    document.removeEventListener('touchmove', handleTouchMove);
                };
            }
            else{
                if (!globalMapElement) return;
                globalMapElement.style.transform = `translate(0px, 0px)`;
                if(!globalMapInnerElement) return;
                globalMapInnerElement.style.transform = `translate(0px, 0px)`;
            }
        }
    }, [loadedApp,width,height,currentKeys,handleTouchMove]);

/*    useEffect(() => {
        document.addEventListener('touchmove', handleTouchMove);
        return () => {
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, [loadedApp,width,height]);*/


    useEffect(() => {
        if(loadedApp) {
            if (!isTouchDevice()) {
                if (width < 1200) {
                    const globalMapElement = document.querySelector('.global-map__canvas') as HTMLElement//globalMapRef.current;
                    if (!globalMapElement) return;

                    const speed = 4;
                    let interval: any | null = null;

                    const updatePosition = () => {
                        const transformValue = globalMapElement.style.transform;
                        // const regex = /translate\((-?\d*\.?\d+)%, (-?\d*\.?\d+)%\)/;
                        const regex = /translate\((-?\d*\.?\d+)px, (-?\d*\.?\d+)px\)/;
                        const match = transformValue.match(regex);


                        let oldPositionX = match ? parseFloat(match[1]) : 0;
                        let oldPositionY = match ? parseFloat(match[2]) : 0;

/*                        const newValue = 2860 - window.innerWidth;
                        const newValueY = 1613 - window.innerHeight;*/

                        const scaleSize = 1.3//1794 982
                        const newValue = (1794 - window.innerWidth)/scaleSize ;
                        const newValueY = (982 - window.innerHeight)/scaleSize;


                        if (isInLeftZoneRef.current && oldPositionX < newValue) {
                            globalMapElement.style.transform = `translate(${Math.min(oldPositionX + speed, 0)}px, ${oldPositionY}px)`;
                        } else if (isInRightZoneRef.current && oldPositionX > -newValue) {
                            globalMapElement.style.transform = `translate(${Math.max(oldPositionX - speed, -newValue)}px, ${oldPositionY}px)`;
                        }
                        if (isInUpZoneRef.current && oldPositionY < newValueY) {
                            globalMapElement.style.transform = `translate(${oldPositionX}px, ${Math.min(oldPositionY + speed, 100 )}px)`;
                        } else if (isInDownZoneRef.current && oldPositionY > -newValueY) {
                            globalMapElement.style.transform = `translate(${oldPositionX}px, ${Math.max(oldPositionY - speed, -newValueY)}px)`;
                        }

                    };

                    interval = setInterval(updatePosition, 10);

                    return () => {
                        if (interval) clearInterval(interval);
                    };
                }
            }
        }
    }, [loadedApp,width,height]);
    const [activeMap,setActiveMap]=useState(false);

    useEffect(() => {
        if(mapLoading && loadedApp ) {
            dispatch(changeCurrentKeys(1));
            //  dispatch(changeModalFirstKey(true));
            localStorage.setItem('keysNumber', "1");
            dispatch(changeCheckBurningMan(true));

            localStorage.setItem('currentAccommodation', 'true');
            dispatch(changeCheckAccommodation(true));

            localStorage.setItem('currentClans', 'true');
            dispatch(changeCheckClans(true));

            localStorage.setItem('currentMeal', 'true');
            dispatch(changeCheckMeals(true))

            localStorage.setItem('currentTravel', 'true');
            dispatch(changeCheckGetThere(true));

            localStorage.setItem('currentMobility', 'true');
            dispatch(changeCheckGetAround(true));

            dispatch(changeMailSend(true));
            localStorage.setItem('sentMail', '1');

            localStorage.setItem('burningEmail','email');
            localStorage.setItem('burningRegisterType','burningRegisterType' as string);
            dispatch(changeModalIntroBack(true))
            dispatch(changeModalIntro(false))
            dispatch(changeFirstEmail(true));
            setTimeout(()=>{
                dispatch(changeModalTutorial(true))
            },7000)
        }
    }, [mapLoading,loadedApp]);

    const zoomRef = useRef<boolean>(false);
    useEffect(() => {
        const needEmail = localStorage.getItem('burningEmail') ? localStorage.getItem('burningEmail') : firstEmail;
        if(mapLoading && loadedApp && !zoomRef.current && needEmail){
        //    console.log('loading')
            if (width < 1200) {
                setTimeout(()=>{
                  //  setActiveMap(true);
            //        gsap.set('.global-map__inner', {transformOrigin: 'left top',x:0,y:0,scale:1.3});
                    const currentHeight = window.innerHeight /4;

                  //  x: 640,
                  //      y: 290,

                    const centerX = -640  + window.innerWidth /3.5 ;
                    // const calcY = type
                    const centerY = -290 + (window.innerHeight > 900 ? window.innerHeight / 3 : window.innerHeight / 3)
                    gsap.fromTo('.global-map__inner',{
                         x: "17%",
                           y: `${currentHeight-100}px`,
                            scale: 0.5,
                            transformOrigin: 'center center',
                            duration: 2.7
                        },
                        {

                       /*     x: `${centerX}px`,
                            y: `${centerY}px`,*/
                            /*  x: "25%",
                            y: `${currentHeight}px`,*/
                            scale: 1.3,
                            duration: 2.7,
                            clearProps: "all",
                            onComplete: function() {
                               // gsap.set('.global-map__inner', {x: '0'});
                               // setActiveMap(true);
   /*                             if(firstEmail){
                                    gsap.set('.global-map__inner', {transformOrigin: 'left top',x:0,y:100,scale:1.3});//
                                }
                                else{*/
                                   gsap.set('.global-map__inner', {transformOrigin: 'center center',scale:1.3});//
                             //   }

                            }
                        });

                    zoomRef.current=true
                },2000)

            }
        }

    }, [width,loadedApp,mapLoading,firstEmail]);
    useEffect(() => {
       // const needEmail = localStorage.getItem('burningEmail') ? localStorage.getItem('burningEmail') : firstEmail;
        if(mapLoading && loadedApp && zoomRef.current && firstEmail){
            //    console.log('loading')
            if (width < 1200) {
                gsap.set('.global-map__inner', {transformOrigin: 'left top',x:0,y:0,scale:1.3});//
            }
        }

    }, [width]);
    return (
        <>
           {/* {appRef.current &&
                <AppProvider value={appRef.current}>*/}
                    {!mapLoading && <Preloader/>}
                    <div
                        ref={globalMapRef}
                        className={`global-map ${currentKeys === 3 ? "night": ""}`}>
                        <div className={`global-map__wrapper ${currentKeys === 3 ? "night": ""}`}>
                            <div className={`global-map__inner  ${currentKeys === 3 ? "night": ""} ${activeMap ? "active": ""}`}>
                                <GlobalMap/>
                            </div>

                        </div>
                    </div>
                    <MobileControls/>
                    <GetTicket/>
                    <KeysPanel/>

                    <Modals/>
             {/*   </AppProvider>*/}
           {/* }*/}


        </>

    );
};

export default GlobalMapContainer;