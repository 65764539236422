import {FC} from 'react';

const BackIcon:FC = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5625 22.5L23.5625 36.5L20 40L0 20L20 0L23.5625 3.5L9.5625 17.5L40 17.5V22.5L9.5625 22.5Z" fill="#946051" fillOpacity="0.5"/>
        </svg>
    );
};

export default BackIcon;