import './KeysPanel.scss';

import {FC, useEffect, useState} from 'react';
import ProfileIcon from "../../icons/ProfileIcon";
import KeyIcon from "../../icons/KeyIcon";
import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";
import {changeCurrentKeys, changeIsCloseSecondModal} from "../../../redux/slices/appSlice";

import {changeCheckInfo} from "../../../redux/slices/checkSlice/checkSlice";

const KeysPanel:FC = () => {
    const dispatch = useDispatchEx();
    const {currentKeys,loadedApp,mapLoading,isCloseSecondModal} = useSelectorEx(state => state.app)
    const {checkInfo} = useSelectorEx(state => state.checks)
    const {modalSecondKey} = useSelectorEx(state => state.modals)
    const [localKey,setLocalKey] = useState(0);

    useEffect(() => {
        const newCount = checkInfo.filter(item=>item!=="scroll").length;
        const count = newCount;//checkInfo.length;
        setLocalKey(count);

        if(count === 15){// 2 15  && currentKeys === 2 && isCloseSecondModal
            dispatch(changeCurrentKeys(3));
            localStorage.setItem('keysNumber',"3");
        }
        if(count === 15 && currentKeys === 1 && !isCloseSecondModal){
       //     dispatch(changeCurrentKeys(2));
        //    dispatch(changeModalSecondKey(true))
         //   localStorage.setItem('keysNumber',"2");
        }
    }, [checkInfo,currentKeys]);

    useEffect(() => {
        if(loadedApp && mapLoading){
            const loadKeysCounter = localStorage.getItem('checkInfo');
            const keysNumber = localStorage.getItem('keysNumber');
            if(keysNumber){
                setTimeout(()=>{
                    dispatch(changeCurrentKeys(Number(keysNumber)))
                },2000)
            }

            if(loadKeysCounter){
                const newValue = loadKeysCounter ? loadKeysCounter.split(',') : [];
                dispatch(changeCheckInfo(newValue));
                if(loadKeysCounter.includes('scroll')){
                    dispatch(changeIsCloseSecondModal(true));
                }
               // dispatch(changeCurrentKeysCounter((Number(loadKeysCounter))))
            }
        }

    }, [loadedApp,mapLoading]);
    return (
        <div className={`keys-panel ${currentKeys === 3 ? "dark": ""}`}>
            <div className="keys-panel__wrapper">
                <div className="keys-panel__counter">{localKey} / 15</div>
 {/*               <div className="keys-panel__keys">
                    <div className={`keys-panel__key ${currentKeys >= 1 ? "active" : ""}`}>
                        <KeyIcon/>
                        <KeyIcon/>
                    </div>
                    <div className={`keys-panel__key ${currentKeys >= 2 ? "active" : ""}`}>
                        <KeyIcon/>
                        <KeyIcon/>
                    </div>
                    <div className={`keys-panel__key ${currentKeys >= 3 ? "active" : ""}`}>
                        <KeyIcon/>
                        <KeyIcon/>
                    </div>

                </div>*/}
                <div  className="keys-panel__icon">
                    <ProfileIcon/>
                </div>
            </div>

        </div>
    );
};

export default KeysPanel;