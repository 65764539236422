import {FC, ReactNode,MouseEvent} from 'react';
import {Link} from "react-router-dom";
import './Button.scss'
import useBubbleHover from "../../../hooks/useBubbleHover";
interface IButton {
    type?: 'link' | 'button' | "htmlLink";
    name: ReactNode;
    className?: string;
    onClick?: ()=>void
    href?:string;
    effect?: "bubble" | "";
    disabled?: boolean;
}

const Button:FC<IButton> = ({disabled=false,type="link",name,className='', onClick, href='/'}) => {
    const ref = useBubbleHover();

    const handleClick = (e: MouseEvent) => {
        if (disabled) {
            e.preventDefault();
            return;
        }
        onClick?.();
    };

    return (

    type === 'htmlLink' ? <div ref={ref} className={`button ${className} ${disabled ? "disabled": ""}`}>

            <a
                onClick={handleClick}
                className={`button__inner `}
                target={"_blank"} href={href as string} >
                <span className="button__effect"></span>
                      <span className="button__name">
                        {name}
                      </span>
            </a>
        </div>
        :
            type === 'button' ?
                <div ref={ref} className={`button ${className} ${disabled ? "disabled": ""}`}>
                    <button
                        disabled={disabled}
                        type={'submit'}
                        onClick={handleClick}
                        className={`button__inner `}
                        >
                        <span className="button__effect"></span>
                      <span className="button__name">
                        {name}
                      </span>
                    </button>
                </div>
                :
                <div ref={ref} className={`button ${className} ${disabled ? "disabled": ""}`}>
                    <Link
                        to={href as string}
                        onClick={handleClick}
                        className={`button__inner `}
                    >
                        <span className="button__effect"></span>
                      <span className="button__name">
                        {name}
                      </span>
                    </Link>
                </div>
    );
};

export default Button;