import {FC, ReactNode, useEffect, useState} from 'react';

import './ModalExperience.scss'
import EditIcon from "../../../icons/EditIcon";
import Button from '../../../atoms/Button/Button';
import {useDispatchEx, useSelectorEx} from "../../../../hooks/redux";
import {
    changeModalAccommodation, changeModalBurningMan, changeModalClans, changeModalFirstKey,
    changeModalGetAround, changeModalGetThere,
    changeModalMeals
} from "../../../../redux/slices/modalSlice/modalSlice";

import {
    changeCheckAccommodation,
    changeCheckClans, changeCheckGetAround, changeCheckGetThere,
    changeCheckMeals,
    changeMailSend
} from "../../../../redux/slices/checkSlice/checkSlice";
import MailService from "../../../../api/MailService";
import {changeCurrentKeys, changeIsCloseSecondModal} from "../../../../redux/slices/appSlice";

interface IModalExperience{
    active:boolean;
}

const ModalExperience:FC<IModalExperience> = ({active}) => {
    const dispatch = useDispatchEx();
    const [clan,setClan] = useState<any|null>(null);
    const [travel,setTravel] = useState<any|null>(null);
    const [accommodations,setAccommodations] = useState<any|null>(null);
    const [meal,setMeal] = useState<any|null>(null);
    const [mobility,setMobility] = useState<any|null>(null);
    const [complete,setComplete] = useState<any|null>(null);
    const [mail,setMail] = useState<any|null>(null);
    const [sentMail,setSentMail] = useState<any|null>(null);
    const [error,setError] = useState<any|null>(null);

    useEffect(() => {
        const travel = localStorage.getItem('currentTravel');
        const accommodations = localStorage.getItem('currentAccommodation');
        const meal = localStorage.getItem('currentMeal');
        const mobility = localStorage.getItem('currentMobility');
        const clans = localStorage.getItem('currentClans');
        const sentMail = localStorage.getItem('sentMail') ? localStorage.getItem('sentMail'): false;
        const email = localStorage.getItem('burningEmail');

        setTravel(travel ? JSON.parse(travel) : null);
        setAccommodations(accommodations ? JSON.parse(accommodations) : null);
        setMeal(meal ? JSON.parse(meal) : null);
        setMobility(mobility ? JSON.parse(mobility) : null);
        setClan(clans ? JSON.parse(clans) : null);
        const isComplete = travel && accommodations && meal && mobility && clans;
        setComplete(isComplete);
        setMail(email);
        setSentMail(sentMail)
    }, [active]);

    useEffect(() => {
        const travel = localStorage.getItem('currentTravel');
        const accommodations = localStorage.getItem('currentAccommodation');
        const meal = localStorage.getItem('currentMeal');
        const mobility = localStorage.getItem('currentMobility');
        const clans = localStorage.getItem('currentClans');
        const email = localStorage.getItem('burningEmail');

        const sentMail = localStorage.getItem('sentMail') ? localStorage.getItem('sentMail'): false;

        dispatch(changeCheckAccommodation(accommodations !== null))
        dispatch(changeCheckClans(clans !== null))
        dispatch(changeCheckMeals(meal !== null))
        dispatch(changeCheckGetThere(travel !== null))
        dispatch(changeCheckGetAround(mobility !== null))
        dispatch(changeMailSend(sentMail))

        setMail(email);
        setSentMail(sentMail)
    }, []);


    const travelPrice = travel?.value ? travel?.value : 0;
    const accommodationsPrice = accommodations?.value ? accommodations?.value : 0;
    const mobilityPrice = mobility?.value ? mobility?.value : 0;
    const mealPrice = meal?.value ? meal?.value : 0;
    const totalPrice = travelPrice+mealPrice+accommodationsPrice+mobilityPrice;

    const onEdit = (callback:any)=>{
        dispatch(changeModalBurningMan(false));
        dispatch(callback);
    }

    const onSend = async ()=>{
        const travel = localStorage.getItem('currentTravel');
        const accommodations = localStorage.getItem('currentAccommodation');
        const meal = localStorage.getItem('currentMeal');
        const mobility = localStorage.getItem('currentMobility');
        const clan = localStorage.getItem('currentClans');
        const email = localStorage.getItem('burningEmail');
        const attendType = localStorage.getItem('burningRegisterType');

        const sendForm = {
            email: email,
            clan: clan,
            accommodations,
            mobility,
            travel,
            meal,
            attendType
        }


        try{
            const result = await MailService.sendGetInfo(sendForm);
            setSentMail(true);
            localStorage.setItem('sentMail','true');
            setError("");
        }
        catch (e:any){
            if(e?.response?.data?.message?.length){
                setError(JSON.stringify(( Array.isArray(e?.response?.data?.message)) ? e?.response?.data?.message![0]: e?.response?.data?.message));
            }
            else{
                setError("Something went wrong");
            }

        }

    }

    return (
        <div className={'modal-experience'}>
            <div className="modal-experience__title">{complete ? "Your Burning Man Experience": "Complete Your Burning Man Experience"}</div>
            <div className="modal-experience__panel">
                <div className="modal-experience__clan">
                    <div className={`modal-experience__clan-img ${clan ? "active": ""}`}>
                        {clan ?
                            <img src={clan.img} alt=""/>
                            :
                            <span>No clan</span>
                        }

                    </div>
                    <div className={`modal-experience__clan-title `}>
                        {clan ?
                            <>
                                <span>{clan.title} <span onClick={()=>onEdit(changeModalClans(true))}><EditIcon/></span></span>
                            </>

                            :
                            <>
                                <span>No Clan Chosen Yet</span>
                                <Button onClick={()=>onEdit(changeModalClans(true))} name={'Choose A Clan'} className="button--primary" />

                            </>

                        }



                    </div>
                </div>
                <div className="modal-experience__item">
                    <div className="modal-experience__item-edit"  onClick={()=>onEdit(changeModalGetThere(true))}>
                        <EditIcon/>
                    </div>
                    <div className={`modal-experience__item-name ${travel ? "active": ""}`}>
                        <span>Travel {travel ? `(${travel?.product})`:""}</span>
                        <span>{travel ? travel?.name:"No Selection"}</span>
                    </div>
                    <div className="modal-experience__item-price">{travel ? `$${travelPrice}`:"—"}</div>
                </div>
                <div className="modal-experience__item">
                    <div className="modal-experience__item-edit" onClick={()=>onEdit(changeModalAccommodation(true))}>
                        <EditIcon/>
                    </div>
                    <div className={`modal-experience__item-name ${accommodations ? "active": ""}`}>
                        <span>Accommodations {accommodations ? `(${accommodations?.product})`:""}</span>
                        <span>{accommodations ? accommodations?.name:"No Selection"}</span>
                    </div>
                    <div className="modal-experience__item-price">{accommodations ? `$${accommodationsPrice}`:"—"}</div>
                </div>
                <div className="modal-experience__item">
                    <div className="modal-experience__item-edit" onClick={()=>onEdit(changeModalMeals(true))}>
                        <EditIcon/>
                    </div>
                    <div className={`modal-experience__item-name ${meal ? "active": ""}`}>
                        <span>Meal Plan</span>
                        <span>{meal ? meal?.name :"No Selection"}</span>
                    </div>
                    <div className="modal-experience__item-price">{mealPrice ? `$${mealPrice}` :"—"}</div>
                </div>
                <div className="modal-experience__item">
                    <div className="modal-experience__item-edit" onClick={()=>onEdit(changeModalGetAround(true))}>
                        <EditIcon/>
                    </div>
                    <div className={`modal-experience__item-name ${mobility ? "active": ""}`}>
                        <span>Mobility {mobility ? `(${mobility?.product})`:""}</span>
                        <span>{mobility ? mobility?.name:"No Selection"}</span>
                    </div>
                    <div className="modal-experience__item-price">{mobility ? `$${mobilityPrice}`:"—"}</div>
                </div>
                <div className="modal-experience__panel-summary">
                    Approximate Total* <span>{totalPrice ? `$${totalPrice}` : "—"}</span>
                </div>
                <div className="modal-experience__panel-info">
                    <span>*This is not a reservation confirmation.</span>
                    Please refer to the Playa Resource Guide in your email with links to where to purchase gear and make your reservations.
                </div>
                <div className="modal-experience__buttons">
                    {sentMail ?
                        <div className="modal-experience__success">
                            This estimate for Your<br/>
                            Burning Man Experience<br/>
                            has been sent to<br/>
                            [{mail}]
                        </div>

                        :
                        <>
                            {/*      <Button type={'htmlLink'} href={'https://tickets.burningman.org/'} name={'Important Ticket Sale Dates'} className="button--yellow" />*/}
                            <Button disabled={!complete} onClick={onSend} name={'Email Me This Estimate'} className="button--primary" />
                            {error && <p style={{color:"#ff0000",textAlign:"right"}}>{error}</p>}
                        </>

                    }


                </div>
            </div>
            <div className="modal-experience__inner">
                <div className="modal-experience__clan">
                    <div className={`modal-experience__clan-img ${clan ? "active": ""}`}>
                        {clan ?
                            <img src={clan.img} alt=""/>
                            :
                            <span>No clan</span>
                        }

                    </div>
                    <div className={`modal-experience__clan-title `}>
                        {clan ?
                            <>
                                <span>{clan.title} <span onClick={()=>onEdit(changeModalClans(true))}><EditIcon/></span></span>
                            </>

                            :
                            <>
                                <span>No Clan Chosen Yet</span>
                                <Button onClick={()=>onEdit(changeModalClans(true))} name={'Choose A Clan'} className="button--primary" />

                            </>

                        }



                    </div>
                </div>
                <div className="modal-experience__content">
                    {complete ?

                        <>
                            <p><span>Congratulations!</span> Your journey to the Playa will be here sooner than you know it! Please refer to the PDF Guide in your email to make your final reservations.</p>
                            <p>It is necessary for you to secure your own Burning Man 2024 tickets, in order to attend the wedding. Please follow the link "Burning Man Tickets" on your home screen to register and purchase the tickets.</p>

                            <p>
                            <span>*Estimates are not final and are meant to give you an idea of the price for the adventure.
                             Please visit our resource guide via email to secure reservations and obtain final costs</span>
                            </p>


                        </>

                        :
                        <>
                            <p><span>You are on your way!</span> </p>
                            <p>But not there yet….</p>
                            <p>Please refer to the display and fill in any fields that are still required. </p>
                            <p>Travel around the digital playa, collect the sacred keys, and learn more about the magic of Burning Man!</p>
                            <p><span>*Estimates are not final and are meant to give you an idea of the price for the adventure. Please visit our resource guide via email to secure reservations and obtain final costs.</span></p>
                        </>
                    }


                </div>
                <div className="modal-experience__buttons">
                    {sentMail ?
                        <div className="modal-experience__success">
                            This estimate for Your<br/>
                            Burning Man Experience<br/>
                            has been sent to<br/>
                            [{mail}]
                        </div>

                        :
                        <>
                      {/*      <Button type={'htmlLink'} href={'https://tickets.burningman.org/'} name={'Important Ticket Sale Dates'} className="button--yellow" />*/}
                            <Button disabled={!complete} onClick={onSend} name={'Email Me This Estimate'} className="button--primary" />
                            {error && <p style={{color:"#ff0000",textAlign:"right"}}>{error}</p>}
                        </>

                    }


                </div>
            </div>
        </div>
    );
};

export default ModalExperience;