// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress {
  position: relative;
  display: block;
  width: 100%;
  min-width: 13.0729166667vw;
  height: 0.5208333333vw;
  border: 0.1041666667vw solid var(--additional-color);
  border-radius: 0.5208333333vw;
}
@media (max-width: 1200px) {
  .progress {
    min-width: 32.7249022164vw;
    height: 1.3037809648vw;
    border: 0.260756193vw solid var(--additional-color);
    border-radius: 100px;
  }
}
@media (max-width: 767px) {
  .progress {
    min-width: 58.3720930233vw;
    height: 2.3255813953vw;
    border: 0.4651162791vw solid var(--additional-color);
    border-radius: 100px;
  }
}
.progress span {
  display: block;
  height: 100%;
  border-radius: 100px;
  background: var(--accent-color);
  transition: all 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Progress/Progress.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,cAAA;EACA,WAAA;EACA,0BAAA;EACA,sBAAA;EACA,oDAAA;EACA,6BAAA;AAAF;AACE;EARF;IASI,0BAAA;IACA,sBAAA;IACA,mDAAA;IAGA,oBAAA;EAEF;AACF;AADE;EAhBF;IAiBI,0BAAA;IACA,sBAAA;IACA,oDAAA;IAGA,oBAAA;EAIF;AACF;AAHE;EACE,cAAA;EACA,YAAA;EAGA,oBAAA;EACA,+BC9BM;ED+BN,yBAAA;AAKJ","sourcesContent":["@import '../../../styles/_variables';\r\n.progress{\r\n  position: relative;\r\n  display: block;\r\n  width: 100%;\r\n  min-width: vwValue(251px);\r\n  height: vwValue(10px);\r\n  border:vwValue(2px) solid $additional;\r\n  border-radius: vwValue(10px);\r\n  @media (max-width:1200px){\r\n    min-width: vwValue(251px,767px);\r\n    height: vwValue(10px,767px);\r\n    border:vwValue(2px,767px) solid $additional;\r\n    -webkit-border-radius: 100px;\r\n    -moz-border-radius: 100px;\r\n    border-radius: 100px;\r\n  }\r\n  @media (max-width:767px){\r\n    min-width: vwValue(251px,430px);\r\n    height: vwValue(10px,430px);\r\n    border:vwValue(2px,430px) solid $additional;\r\n    -webkit-border-radius: 100px;\r\n    -moz-border-radius: 100px;\r\n    border-radius: 100px;\r\n  }\r\n  span{\r\n    display: block;\r\n    height: 100%;\r\n    -webkit-border-radius: 100px;\r\n    -moz-border-radius: 100px;\r\n    border-radius: 100px;\r\n    background: $accent;\r\n    transition: all 0.3s ease;\r\n  }\r\n}","$main: var(--primary-color);\r\n$accent:  var(--accent-color);\r\n$additional: var(--additional-color);\r\n\r\n@function vwValue( $value, $base-widthEx:1920px) {\r\n  @return ($value / $base-widthEx * 100vw);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
