import './Preloader.scss';
import {FC} from 'react';
import Progress from "../Progress/Progress";
import {useSelectorEx} from "../../../hooks/redux";

const Preloader:FC = () => {
    const {preloaderPercent} = useSelectorEx(state => state.app)
    return (
        <div className={`preloader ${preloaderPercent === 100 ? "out" : ""}`}>
            <div className="preloader__background"></div>
            <div className="preloader__wrapper">
                <div className="preloader__img"></div>
                <Progress percent={preloaderPercent} />
            </div>

        </div>
    );
};

export default Preloader;