import './ModalFinal.scss';

import {FC, ReactNode, useEffect, useRef} from 'react';
import Button from '../../atoms/Button/Button';
import {changeDisabledCanvasHover} from "../../../redux/slices/appSlice";
import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";
import fireSound from "../../../assets/audio/fire2.mp3";
import {changeModalNight} from "../../../redux/slices/modalSlice/modalSlice";

interface IModalFinal{
    active?: boolean;
    title?: ReactNode;
    onClose?: ()=>void;
}

const ModalFinal:FC<IModalFinal> = ({active=false,title,onClose=()=>{}}) => {
    const {modalNight} = useSelectorEx(state => state.modals)
    const dispatch = useDispatchEx();
    const fireSoundRef = useRef<any>(null)
    const onCloseEx = ()=>{
        dispatch(changeDisabledCanvasHover(true));
        dispatch(changeModalNight(true));
        setTimeout(()=>{
            fireSoundRef.current?.pause();
        },1000)
        onClose();
    }
    useEffect(() => {
        if(active){
            if (localStorage.getItem('userInteracted') === 'true') {
                if (!fireSoundRef.current && !modalNight) {
                    fireSoundRef.current = new Audio()
                    fireSoundRef.current.src = fireSound;
                    fireSoundRef.current.autoplay = true;
                    fireSoundRef.current.loop = true;
                    fireSoundRef.current.volume = 0.3;
                    fireSoundRef.current.play();
                }
            }
        }
        localStorage.removeItem('userInteracted');

        const handleUserInteraction = () => {
            localStorage.setItem('userInteracted', 'true');
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction); // Remove touchstart event listener
        };

        window.addEventListener('click', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction); // Add touchstart event listener

        return () => {
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction); // Remove touchstart event listener when cleaning up
        };
    }, [active]);

    const onClickFire = ()=>{
        if (!fireSoundRef.current) {
            fireSoundRef.current = new Audio()
            fireSoundRef.current.src = fireSound;
            fireSoundRef.current.autoplay = true;
            fireSoundRef.current.loop = true;
            fireSoundRef.current.volume = 0.3;
            fireSoundRef.current.play();
        }
    }
    return (
        <div onClick={onClickFire} className={`modal-final ${active ? "active": ""}`}>
            <div className="modal-final__wrapper">
                <div className="modal-final__inner">
                    <div className="modal-final__title">Congratulations!</div>
                    <div className="modal-final__content">
                        Your journey to the Playa will be here sooner than you know it! Get ready for an extraordinary adventure filled with creativity, connection and unforgettable moments. And don’t forget to get your tickets—click the link below to get started.
                        <div className="modal-final__button">
                            <Button type={'link'}  className={'modal-final__back button--light'} onClick={onCloseEx} name={'Back'} />
                            <Button type={'htmlLink'}  href={'https://tickets.burningman.org/'} className={'button--light'} name={'Burning Man Ticket Info'} />
                        </div>

                    </div>
                </div>

            </div>

        </div>
    );
};

export default ModalFinal;