import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {boolean} from "yup";


interface modalState {
    modalAccommodation:boolean;
    modalClans:boolean;
    modalMeal:boolean;
    modalBurningMan:boolean;
    modalGetAround:boolean;
    modalGetThere:boolean;
    modalFAQ:boolean;
    modalPrinciples:boolean;
    modalArtCar:boolean;

    modalFirstKey:boolean;
    modalSecondKey:boolean;
    modalLastKey:boolean;

    modalNight:boolean;

    modalLoves:boolean;
    modalTutorial:boolean;
    modalDates: boolean;
    modalPurchase: boolean;

    modalInfo:boolean;
    modalInfoCurrent: string;


    isLoading: boolean;
    error: string;
    errorCode: string;
    successMessage: string;
    successMessageError: string;
}

const initialState: modalState = {
    modalAccommodation: false,
    modalClans:false,
    modalMeal:false,
    modalBurningMan:false,
    modalGetAround:false,
    modalGetThere:false,
    modalFAQ: false,
    modalPrinciples: false,
    modalArtCar: false,

    modalFirstKey:false,
    modalSecondKey:false,
    modalLastKey:false,

    modalNight:false,

    modalLoves:false,
    modalTutorial: false,
    modalDates:false,

    modalInfo: false,
    modalInfoCurrent: "",

    modalPurchase: false,

    isLoading: false,
    error: '',
    errorCode: '',
    successMessage: '',
    successMessageError: ""
};


const modalSlice = createSlice({
    name:'modalSlice',
    initialState,
    reducers:{

        changeModalPurchase(state,action){
            state.modalPurchase = action.payload;
        },
        changeModalNight(state,action){
            state.modalNight = action.payload;
        },
        changeModalLoves(state,action){
            state.modalLoves = action.payload;
        },
        changeModalDates(state,action){
            state.modalDates = action.payload;
        },
        changeModalTutorial(state,action){
            state.modalTutorial = action.payload;
        },

        changeModalAccommodation(state,action){
            state.modalAccommodation = action.payload;
        },
        changeModalClans(state,action){
            state.modalClans = action.payload;
        },
        changeModalMeals(state,action){
            state.modalMeal = action.payload;
        },
        changeModalGetThere(state,action){
            state.modalGetThere = action.payload;
        },
        changeModalGetAround(state,action){
            state.modalGetAround = action.payload;
        },
        changeModalFAQ(state,action){
            state.modalFAQ = action.payload;
        },
        changeModalBurningMan(state,action){
            state.modalBurningMan = action.payload;
        },


        changeModalFirstKey(state,action){
            state.modalFirstKey = action.payload;
        },
        changeModalSecondKey(state,action){
            state.modalSecondKey = action.payload;
        },
        changeModalLastKey(state,action){
            state.modalLastKey = action.payload;
        },

        changeModalInfo(state,action){
            state.modalInfo = action.payload;
        },
        changeModalInfoCurrent(state,action){
            state.modalInfoCurrent = action.payload;
        },
        changeModalPrinciples(state,action){
            state.modalPrinciples = action.payload;
        },
        changeModalArtCar(state,action){
            state.modalArtCar = action.payload;
        },

    },
    extraReducers: (builder:any) => {

    },
});
export const {
    changeModalPurchase,
    changeModalNight,
    changeModalDates,
    changeModalTutorial,
    changeModalLoves,
    changeModalAccommodation,
    changeModalClans,
    changeModalMeals,
    changeModalGetThere,
    changeModalGetAround,
    changeModalFAQ,
    changeModalBurningMan,
    changeModalFirstKey,
    changeModalSecondKey,
    changeModalInfo,
    changeModalInfoCurrent,
    changeModalPrinciples,
    changeModalArtCar

} = modalSlice.actions;
export default modalSlice.reducer;