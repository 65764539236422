import {FC, ReactNode, useEffect, useRef} from 'react';
import {gsap} from "gsap";
import './ModalSingle.scss'

interface IModalSingle{
    active?: boolean;
    img?: string;
    title?: ReactNode;
    children?:  ReactNode;
    buttons?: ReactNode;
}

const ModalSingle:FC<IModalSingle> = ({active=false,img, title,children,buttons}) => {
    const imgRef = useRef<any>(null);
    const contentRef = useRef<any>(null);
    useEffect(() =>{
        const timeline = gsap.timeline()
        timeline.fromTo(imgRef.current,{
            opacity:0,
        },{
            opacity:1,
            duration:1
        },0.3)
        timeline.fromTo(contentRef.current,{
            opacity:0,
        },{
            opacity:1,
            duration:1
        },0.6)
    },[active])


    return (
        <div className={'modal-single'}>
            <div ref={imgRef} className="modal-single__img">
                <img src={img} alt=""/>
            </div>
            <div ref={contentRef} className="modal-single__inner">
                <div className="modal-single__title" >{title}</div>
                <div  className="modal-single__content" >
                    {children}
                </div>
                <div className="modal-single__buttons">
                    {buttons}
                </div>
            </div>
        </div>
    );
};

export default ModalSingle;