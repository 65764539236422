import {FC, memo, useCallback, useEffect, useRef, useState} from 'react';
import {Sprite} from "@pixi/react";
import hide from "../../../assets/img/hide.png";
import firemanBig from "../../../assets/img/main_items/fireman_low.png";
import accomodationsBig from "../../../assets/img/main_items/accomodations_low.png";
import clansBig from "../../../assets/img/main_items/clans_low.png";
import carsBig from "../../../assets/img/main_items/cars_low.png";
import mobilityBig from "../../../assets/img/main_items/mobility_low.png";
import mealBig from "../../../assets/img/main_items/meal_low.png";
import dragonBig from "../../../assets/img/animation/dragon.png";

import firemanNormal from "../../../assets/img/main_items/normal/fireman_low.png";
import accomodationsNormal from "../../../assets/img/main_items/normal/accomodations_low.png";
import clansNormal from "../../../assets/img/main_items/normal/clans_low.png";
import carsNormal from "../../../assets/img/main_items/normal/cars_low.png";
import mobilityNormal from "../../../assets/img/main_items/normal/mobility_low.png";
import mealNormal from "../../../assets/img/main_items/normal/meal_low.png";
import dragonNormal from "../../../assets/img/animation/normal/dragon.png";


import {AdjustmentFilter} from "@pixi/filter-adjustment";
import {gsap} from "gsap";
import {animationFinish, animationStart} from "../../../helpers/animations";
import useCalculateSize from "../../../hooks/useCalculateSize";
import useWindowSize from "../../../hooks/useWindowSize";
import {
    changeModalAccommodation,
    changeModalBurningMan,
    changeModalClans, changeModalGetAround,
    changeModalGetThere, changeModalInfo, changeModalInfoCurrent, changeModalMeals
} from "../../../redux/slices/modalSlice/modalSlice";

import { Rectangle } from 'pixi.js';
import {GlowFilter} from "@pixi/filter-glow";

interface IAnimationsItems{
    firstEmail?:boolean;
    currentKey:any;
    dispatch?:any;
    mapLoading?: boolean;
    loadedApp?: boolean;
    interactive?:boolean;
        checks?:any
}

const imagesSizesList = {
    2600:[firemanNormal,accomodationsNormal,clansNormal,carsNormal,mobilityNormal,mealNormal,dragonNormal],
    3800:[firemanBig,accomodationsBig,clansBig,carsBig,mobilityBig,mealBig,dragonBig]
}
const [fireman,
    accomodations,
    clans,
    cars,
    mobility,meal,dragon] = window.innerWidth > 2600 ? imagesSizesList[3800]: imagesSizesList[2600];
const hiddenIndex = -1;
const settingsHiddenCheck = {
    pixi: {
        zIndex: hiddenIndex
    }
};

const contrastFilterEx= new GlowFilter({
    distance:15,
    innerStrength:0,
    outerStrength:2,
    color:0xffffff,
    quality:0.2,
    alpha:1
})

const MainItems:FC<IAnimationsItems> = memo(({firstEmail,currentKey,checks={},interactive=true,dispatch=()=>{},mapLoading=false,loadedApp=false}) => {

    const calculateSize = useCalculateSize();
    const {width,height} = useWindowSize();
    const timeTouchRef = useRef<any>(null)
    const currentItem = useRef<any>(null);

    const filterStage = useRef<any>(null);

    const firemanRef = useRef<any>();
    const accomodationsRef = useRef<any>();
    const clansRef = useRef<any>();
    const carsRef = useRef<any>();
    const mobilityRef = useRef<any>();
    const mealRef = useRef<any>();
    const dragonRef = useRef<any>();

    const isLoaded = useRef<any>();

    const {checkAccommodation,checkClans,checkGetAround,checkGetThere,checkMeal,checkBurningMan,checkInfo} = checks;

    function visibleAll(){
        const alphaAnimate = {
            pixi:{
                alpha:1
            }
        }
        gsap.set(firemanRef.current, alphaAnimate);
        gsap.set(accomodationsRef.current, alphaAnimate);
        gsap.set(clansRef.current, alphaAnimate);
        gsap.set(carsRef.current, alphaAnimate);
        gsap.set(mobilityRef.current, alphaAnimate);
        gsap.set(mealRef.current, alphaAnimate);
        gsap.set(dragonRef.current, alphaAnimate);
    }


    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
        const needEmail = localStorage.getItem('burningEmail') ? localStorage.getItem('burningEmail') : firstEmail;
        if( mapLoading && loadedApp && !isLoaded.current && needEmail && Object.keys(checks).length !== 0){ //&& !isLoaded.current

            timeoutId=setTimeout(() => {
                const timeline = gsap.timeline();

                const allRefs = [
                    accomodationsRef,
                    mealRef,
                    firemanRef,
                    clansRef,
                    mobilityRef,
                    carsRef,
                    dragonRef
                ]

                allRefs.forEach((ref,index) => {
                    if (ref.current) {
                        timeline.fromTo(ref.current, animationStart(ref.current,2), animationFinish(ref.current,2), 0.3);
                    }
                });
                if (checks?.checkAccommodation) {
                    timeline.set(accomodationsRef.current, settingsHiddenCheck, 0.5);
                }
                if (checks?.checkClans) {
                    timeline.set(clansRef.current, settingsHiddenCheck, 0.5);
                }
                if (checks?.checkGetAround) {
                    timeline.set(mobilityRef.current, settingsHiddenCheck, 0.5);
                }
                if (checks?.checkGetThere) {
                    timeline.set(carsRef.current, settingsHiddenCheck, 0.5);
                    timeline.set(dragonRef.current, settingsHiddenCheck, 0.5);
                }
                if (checks?.checkMeal) {
                    timeline.set(mealRef.current, settingsHiddenCheck, 0.5);
                }
                if (checks?.checkBurningMan) {
                    timeline.set(firemanRef.current, settingsHiddenCheck, 0.5);
                }
                isLoaded.current = true;

            },2200)
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [mapLoading,width,height,loadedApp,firstEmail,checks]);

    useEffect(() => {
        if( mapLoading && loadedApp){
            const timelineInteractive = gsap.timeline();


            //visibleAll();


/*            gsap.set(currentItem.current, {
                pixi:{
                    zIndex:3
                }
            });*/
            if(interactive){

                timelineInteractive.to(filterStage.current, {
                    pixi: {
                        alpha: 0,
                    },
                    duration: 0.3
                },0);

              /*  timelineInteractive.set(currentItem.current, {
                    pixi:{
                        zIndex:1
                    }
                },0.4);*/


                if(currentItem.current){
                    const contrastFilter = new AdjustmentFilter({ contrast: 1.0 });
                    if(currentKey >= 1 && currentKey < 3 && !checkInfo.includes('theMan')){
                        currentItem.current.filters =  [contrastFilterEx];
                    }
                    else{
                        currentItem.current.filters = [contrastFilter];
                    }
                }

            }

        }
    }, [interactive]);

    useEffect(() => {
        if( mapLoading && loadedApp && isLoaded.current && Object.keys(checks).length !== 0) {

            visibleAll();



            const timelineInteractive = gsap.timeline();
            if (checks?.checkAccommodation) {
                timelineInteractive.set(accomodationsRef.current, settingsHiddenCheck, 0.5);
            }
            if (checks?.checkClans) {
                timelineInteractive.set(clansRef.current, settingsHiddenCheck, 0.5);
            }
            if (checks?.checkGetAround) {
                timelineInteractive.set(mobilityRef.current, settingsHiddenCheck, 0.5);
            }
            if (checks?.checkGetThere) {
                timelineInteractive.set(carsRef.current, settingsHiddenCheck, 0.5);
                timelineInteractive.set(dragonRef.current, settingsHiddenCheck, 0.5);
            }
            if (checks?.checkMeal) {
                timelineInteractive.set(mealRef.current, settingsHiddenCheck, 0.5);
            }
            if (checks?.checkBurningMan) {
                timelineInteractive.set(firemanRef.current, settingsHiddenCheck, 0.5);
            }
        }
    }, [mapLoading,loadedApp,checks,width,height,isLoaded]);


    const timeline = gsap.timeline();
    const mouseOver = useCallback((event:any) => {
        if (!interactive) return;
        if(!event.target) return;
        const contrastFilter = new AdjustmentFilter({  });
        if(currentKey >= 1 && currentKey < 3 && !checkInfo.includes('theMan')){
            timeline.to(event.target, {
                pixi: {
                    zIndex: 5
                },
                duration: 0.1
            }, 0.1);

            event.target.filters =  [contrastFilterEx];
        }
        else{
            timeline.to(event.target, {
                pixi: {
                    zIndex: 3
                },
                duration: 0.1
            }, 0.1);

            event.target.filters = [contrastFilter];
        }
        document.body.style.cursor = 'pointer';


        gsap.to(filterStage.current,{
            pixi: {
                alpha: 1,
            },
            duration: 0.7
        });
        currentItem.current = event.target;
    }, [interactive,currentKey]); // Add 'interactive' as a dependency

    const mouseOut = useCallback((event:any) => {
        if (!interactive) return;
        if(!event.target) return;
        if(currentKey >= 1 && currentKey < 3 && !checkInfo.includes('theMan')){
            event.target.filters =  [contrastFilterEx];
        }
        else{
            event.target.filters = [];
        }

        document.body.style.cursor = 'default';
        const timelineOut = gsap.timeline();
        timeline.to(event.target, {
            pixi: {
                zIndex: 1
            },
            duration: 0.1
        }, 0.3);

        gsap.to(filterStage.current,{
            pixi: {
                alpha: 0,
            },
            duration: 0.7
        });

        currentItem.current = null
    }, [interactive,currentKey]); // Add 'interactive' as a dependency

    const defaultIndex = 0;



    const onClickBurningMan = () => {
        if(width < 1200 && timeTouchRef.current+100 <= new Date().getTime()) return
        if(!currentKey){
            dispatch(changeModalBurningMan(true))
        }
        else{
            dispatch(changeModalInfo(true));
            dispatch(changeModalInfoCurrent("theMan"))
        }
    }

    const onTouchStart = () => {
        timeTouchRef.current = new Date().getTime();
    }

    const onTouchObserverEnd = (callback:any) => {
        if(width < 1200 && timeTouchRef.current+100 <= new Date().getTime()) return
        dispatch(callback);
    }


    return (
        <>
            <Sprite
                zIndex={(currentKey >= 1 && currentKey < 3 && !checkInfo.includes('theMan')) ? 4 : 2}
                alpha={0}
                ref={filterStage}
                width={calculateSize(5760)}
                height={calculateSize(3240)}
                x={0}//calculateSize(2263)
                y={0}//calculateSize(767)
                image={hide}
            />

            <Sprite
                zIndex={defaultIndex}
                ref={firemanRef}
                alpha={0}
                width={calculateSize(910)}
                height={calculateSize(1078)}
                x={calculateSize(2263)} //calculateSize(2263)
                y={calculateSize(767)} //calculateSize(767)
                image={fireman}

                hitArea={ width < 1200 ? new Rectangle(50, 50, 600/2.5, 1078/2.5) : null} // hitArea={new Rectangle(0, 0, 600, 1078)}
                interactive={(currentKey) ? !checkInfo.includes('theMan') : !checkBurningMan}
                onmouseover={mouseOver}
                onmouseout={mouseOut}
                onclick={onClickBurningMan}
                ontouchstart={onTouchStart}
                ontouchend={onClickBurningMan}
               //ontouchmove={()=>onTouchEnd('fireman')}
                filters={(currentKey >= 1 && currentKey < 3 && !checkInfo.includes('theMan')) ? [contrastFilterEx]: []}
            />

            <Sprite
                zIndex={defaultIndex}
                ref={accomodationsRef}
                alpha={0}
                width={calculateSize(671)}
                height={calculateSize(573)}
                x={calculateSize(613)}//calculateSize(2263)
                y={calculateSize(347)}//calculateSize(767)
                image={accomodations}
                interactive={!checkAccommodation}
                onmouseover={mouseOver}
                onmouseout={mouseOut}
                onclick={()=>dispatch(changeModalAccommodation(true))}
                ontouchstart={onTouchStart}
                ontouchend={()=>onTouchObserverEnd(changeModalAccommodation(true))}
            />
            <Sprite
                zIndex={defaultIndex}
                ref={clansRef}
                alpha={0}
                width={calculateSize(366)}
                height={calculateSize(388)}
                x={calculateSize(2813)}//calculateSize(2263)
                y={calculateSize(347)}//calculateSize(767)
                image={clans}
                interactive={!checkClans}
                onmouseover={mouseOver}
                onmouseout={mouseOut}
                onclick={()=>dispatch(changeModalClans(true))}
                ontouchstart={onTouchStart}
                ontouchend={()=>onTouchObserverEnd(changeModalClans(true))}
            />
            <Sprite
                zIndex={defaultIndex}
                ref={carsRef}
                alpha={0}
                width={calculateSize(1008)}
                height={calculateSize(855)}
                x={calculateSize(253)}//calculateSize(2263)
                y={calculateSize(2207)}//calculateSize(767)
                image={cars}
                interactive={!checkGetThere}
                onmouseover={mouseOver}
                onmouseout={mouseOut}
                onclick={()=>dispatch(changeModalGetThere(true))}
                ontouchstart={onTouchStart}
                ontouchend={()=>onTouchObserverEnd(changeModalGetThere(true))}
            />
            <Sprite
                zIndex={defaultIndex}
                ref={mobilityRef}
                alpha={0}
                width={calculateSize(399)}
                height={calculateSize(540)}
                x={calculateSize(3753)}//calculateSize(2263)
                y={calculateSize(977)}//calculateSize(767)
                image={mobility}
                interactive={!checkGetAround}
                onmouseover={mouseOver}
                onmouseout={mouseOut}
/*                ontouchstart={mouseOver}
                ontouchend={mouseOut}*/
                onclick={()=>dispatch(changeModalGetAround(true))}
                ontouchstart={onTouchStart}
                ontouchend={()=>onTouchObserverEnd(changeModalGetAround(true))}
            />
            <Sprite
                zIndex={defaultIndex}
                ref={mealRef}
                alpha={0}
                width={calculateSize(349)}
                height={calculateSize(653)}
                x={calculateSize(1253)}//calculateSize(2263)
                y={calculateSize(757)}//calculateSize(767)
                image={meal}
                interactive={!checkMeal}
                onmouseover={mouseOver}
                onmouseout={mouseOut}
                onclick={()=>dispatch(changeModalMeals(true))}
                ontouchstart={onTouchStart}
                ontouchend={()=>onTouchObserverEnd(changeModalMeals(true))}
            />

            <Sprite
                ref={dragonRef}
                alpha={0}
                x={calculateSize(4183)}//calculateSize(2263)
                y={calculateSize(1917)}//calculateSize(767)
                width={calculateSize(662)}
                height={calculateSize(607)}
                image={dragon}
                zIndex={defaultIndex}
                interactive={!checkGetThere}
                onmouseover={mouseOver}
                onmouseout={mouseOut}
                onclick={()=>dispatch(changeModalGetThere(true))}
                ontouchstart={onTouchStart}
                ontouchend={()=>onTouchObserverEnd(changeModalGetThere(true))}
            />
        </>
    );
});

export default MainItems;