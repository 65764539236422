import { useState, useEffect, useMemo } from 'react';
import useWindowSize from "./useWindowSize";
const useCalculateSize = (baseSize = 5760) => {
    const { width,height } = useWindowSize();

    const calculateSize = useMemo(() => {
/*        if(width < 767){
            return (currentSize:number, baseSize =  1460) => {
                return ((currentSize / baseSize) * 100)  * (window.innerWidth / 100);
            }
        }*/
        if(width < 1200){
            return (currentSize:number, baseSize =  2660) => {
                return currentSize/4;
            }
        }
        else{
            return (currentSize:number, baseSize =  5760) => {
                return ((currentSize / baseSize) * 100)  * (window.innerWidth / 100);
            }
        }

    }, [width, baseSize]);

    return calculateSize;
};

export default useCalculateSize;