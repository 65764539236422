import {FC, memo, useEffect, useMemo, useRef} from 'react';
import {Sprite} from "@pixi/react";
import {gsap} from "gsap";
import {animationFinish, animationStart} from "../../../helpers/animations";
import {Rectangle, Texture} from "pixi.js";
import dancers from "../../../assets/img/second_items/dancers.png";
import useCalculateSize from "../../../hooks/useCalculateSize";
import useWindowSize from "../../../hooks/useWindowSize";
interface CropRectangle {
    x: number;
    y: number;
    width: number;
    height: number;
}


const useCroppedTextures = (imageSource: string, cropRectangles: CropRectangle[]): Texture[] => {
    return useMemo(() => {
        const originalTexture = Texture.from(imageSource);
        return cropRectangles.map(({ x, y, width, height }) => {
            const cropRectangle = new Rectangle(x, y, width, height);
            return new Texture(originalTexture.baseTexture, cropRectangle);
        });
    }, [imageSource, cropRectangles]);
};

const cropRectangles: CropRectangle[] = [
    { x: 0, y: 215, width: 354, height: 389 },
    { x: 384, y: 83, width: 390, height: 520 },
    { x: 769, y: 170, width: 296, height: 425 },
    { x: 1064, y: 310, width: 252, height: 294 },
    { x: 1062, y: 47, width: 265, height: 262 },
    { x: 1320, y: 280, width: 278, height: 324 },
    { x: 1332, y: 0, width: 260, height: 279 },
    { x: 1605, y: 96, width: 253, height: 506 },
];
interface IAnimationsItems{
    dispatch?:any;
    isOpenKeyModal?:boolean;
    mapLoading?: boolean;
    loadedApp?: boolean;
    interactive?:boolean;
    checks?:string[];
    currentKey?:any;
    firstEmail?:boolean;
}
const DancersItems:FC<IAnimationsItems> = memo(({firstEmail,mapLoading=false,loadedApp=false}) => {
    const calculateSize = useCalculateSize();
    const {width,height} = useWindowSize();
    const dancers1Ref = useRef<any>(null);
    const dancers2Ref = useRef<any>(null);
    const dancers3Ref = useRef<any>(null);
    const dancers4Ref = useRef<any>(null);
    const dancers5Ref = useRef<any>(null);
    const dancers6Ref = useRef<any>(null);
    const dancers7Ref = useRef<any>(null);
    const dancers8Ref = useRef<any>(null);
    const isLoaded = useRef<any>();
    const currentAlpha = useRef<number>(0);

    useEffect(() => {

        let timeoutId: NodeJS.Timeout | null = null;
        const needEmail =  localStorage.getItem('burningEmail') ? localStorage.getItem('burningEmail') : firstEmail;
        if( (mapLoading && loadedApp) && !isLoaded.current && needEmail ){

            timeoutId=setTimeout(() => {

                const dancersRefs = [
                    dancers1Ref,
                    dancers2Ref,
                    dancers3Ref,
                    dancers4Ref,
                    dancers5Ref,
                    dancers6Ref,
                    dancers7Ref,
                    dancers8Ref,
                ]
                const timeline = gsap.timeline();

                dancersRefs.forEach((ref,index) => {
                    if (ref.current) {
                        timeline.fromTo(ref.current, animationStart(ref.current), animationFinish(ref.current), 2.0);
                    }
                });
                setTimeout(()=>{
                    currentAlpha.current=1;
                },2000)
                isLoaded.current = true;

            },1000)
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [mapLoading,width,height,loadedApp,firstEmail]);
    function visibleAll(){
        const alphaAnimate = {
            pixi:{
                alpha:1
            }
        }
        const allRefs = [
            dancers1Ref,
            dancers2Ref,
            dancers3Ref,
            dancers4Ref,
            dancers5Ref,
            dancers6Ref,
            dancers7Ref,
            dancers8Ref,
        ];
        const timelineEx = gsap.timeline();
        allRefs.forEach((ref,index) => {
            if (ref.current) {
                timelineEx.set(ref.current, alphaAnimate);
            }
        });
    }
    const defaultAlpha = (currentAlpha.current) ? currentAlpha.current :  0;
    const croppedDancers = useCroppedTextures(dancers, cropRectangles);
    return (
        <>
            <Sprite
                ref={dancers1Ref}
                alpha={defaultAlpha}
                anchor={{x: 0.5, y: 0.5}}
                x={calculateSize(2035)}
                y={calculateSize(1750)}
                width={calculateSize(354)}
                height={calculateSize(389)}
                texture={croppedDancers[0]}

            />
            <Sprite
                ref={dancers2Ref}
                alpha={defaultAlpha}
                anchor={{x: 0.5, y: 0.5}}
                x={calculateSize(3055)}
                y={calculateSize(1070)}
                width={calculateSize(390)}
                height={calculateSize(520)}
                texture={croppedDancers[1]}

            />
            <Sprite
                ref={dancers3Ref}
                alpha={defaultAlpha}
                anchor={{x: 0.5, y: 0.5}}
                x={calculateSize(3725)}
                y={calculateSize(770)}
                width={calculateSize(296)}
                height={calculateSize(425)}
                texture={croppedDancers[2]}

            />
            <Sprite
                ref={dancers4Ref}
                alpha={defaultAlpha}
                anchor={{x: 0.5, y: 0.5}}
                x={calculateSize(3425)}
                y={calculateSize(1570)}
                width={calculateSize(252)}
                height={calculateSize(294)}
                texture={croppedDancers[3]}

            />
            <Sprite
                ref={dancers5Ref}
                alpha={defaultAlpha}
                anchor={{x: 0.5, y: 0.5}}
                x={calculateSize(4455)}
                y={calculateSize(1190)}
                width={calculateSize(265)}
                height={calculateSize(262)}
                texture={croppedDancers[4]}

            />
            <Sprite
                ref={dancers6Ref}
                alpha={defaultAlpha}
                anchor={{x: 0.5, y: 0.5}}
                x={calculateSize(3155)}
                y={calculateSize(270)}
                width={calculateSize(278)}
                height={calculateSize(324)}
                texture={croppedDancers[5]}

            />
            <Sprite
                ref={dancers7Ref}
                alpha={defaultAlpha}
                anchor={{x: 0.5, y: 0.5}}
                x={calculateSize(3555)}
                y={calculateSize(300)}
                width={calculateSize(260)}
                height={calculateSize(279)}
                texture={croppedDancers[6]}
            />

            <Sprite
                ref={dancers8Ref}
                zIndex={-4}
                alpha={defaultAlpha}
                anchor={{x: 0.5, y: 0.5}}
                width={calculateSize(253)}
                height={calculateSize(506)}
                texture={croppedDancers[7]}
                x={height < 700 ?  calculateSize(5075): calculateSize(5075)}
                y={height < 700 ?  calculateSize(680): calculateSize(480)}
            />
        </>
    );
});

export default DancersItems;