import './GetTicket.scss';
import {FC, useState} from 'react';
import TicketIcon from "../../icons/TicketIcon";
import Button from "../../atoms/Button/Button";
import {useDispatchEx, useSelectorEx} from '../../../hooks/redux';
import LoveIcon from "../../icons/LoveIcon";
import {changeModalLoves} from "../../../redux/slices/modalSlice/modalSlice";

const GetTicket:FC = () => {
    const [open,setOpen] = useState(false);
    const {currentKeys} = useSelectorEx(state=>state.app)
    const dispatch = useDispatchEx();
    return (
        <div className={`get-ticket ${currentKeys === 3 ? "hide": ""}`}>
            <div className={`get-ticket__burger ${open ? "active": ""}`} onClick={()=>setOpen(!open)}>
                <span></span>
                <span></span>
            </div>
            <div className={`get-ticket__wrapper ${open ? "active": ""}`}>

                <Button type={'htmlLink'} href={'https://tickets.burningman.org/'} className={'button--yellow'} name={<><TicketIcon/> <span className={'get-ticket__name'}>Burning Man Tickets</span></>} />
            </div>
          </div>
    );
};

export default GetTicket;