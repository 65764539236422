import {FC, memo, useEffect, useMemo, useRef} from 'react';
import {gsap} from "gsap";
import {Sprite} from "@pixi/react";

import {Rectangle, Texture} from "pixi.js";
import useCalculateSize from "../../../hooks/useCalculateSize";
import useWindowSize from "../../../hooks/useWindowSize";
import fires from "../../../assets/img/fire/fires.png";

import fireSound from '../../../assets/audio/fire2.mp3'

interface IAnimationsItems{
    dispatch?:any;
    mapLoading?: boolean;
    loadedApp?: boolean;
    interactive?:boolean;
    checks?:string[];
    currentKey?:any;
}

const FireAnimation:FC<IAnimationsItems> = memo(({currentKey,checks=[],interactive=true,dispatch=()=>{},mapLoading=false,loadedApp=false}) => {
    const calculateSize = useCalculateSize();
    const {width,height} = useWindowSize();

    const audioRef = useRef<any>(new Audio());
    const fire1Ref = useRef<any>();
    const fire2Ref = useRef<any>();
    const fire3Ref = useRef<any>();

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;

        if( mapLoading && loadedApp && currentKey === 3){
            timeoutId=setTimeout(() => {

                const timeline = gsap.timeline({
                    repeat: -1,
                    repeatDelay:1
                });
                const timelineEx = gsap.timeline({
                });
                timelineEx.fromTo(fire1Ref.current, {
                    pixi:{
                        alpha:0
                    }
                },{
                    pixi:{
                        alpha:1
                    },

                },0.3);
                timeline.fromTo(fire2Ref.current, {
                    pixi:{
                        alpha:0
                    }
                },{
                    pixi:{
                        alpha:1
                    }
                },1.3);
                timeline.fromTo(fire3Ref.current, {
                    pixi:{
                        alpha:0
                    }
                },{
                    pixi:{
                        alpha:1
                    },
                },2.3);
           /*     setTimeout(()=>{

                },1300)*/


            },2000)
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

        };
    }, [mapLoading,loadedApp,currentKey]);

    const croppedFire1:any = useMemo(() => {
        let originalTexture = Texture.from(fires);
        let cropRectangle = new Rectangle(0, 440, 251, 262);
        return new Texture(originalTexture.baseTexture, cropRectangle);
    }, [fires]);
    const croppedFire2:any = useMemo(() => {
        let originalTexture = Texture.from(fires);
        let cropRectangle = new Rectangle(250, 230, 335, 470);
        return new Texture(originalTexture.baseTexture, cropRectangle);
    }, [fires]);
    const croppedFire3:any = useMemo(() => {
        let originalTexture = Texture.from(fires);
        let cropRectangle = new Rectangle(583, 0, 405, 704);
        return new Texture(originalTexture.baseTexture, cropRectangle);
    }, [fires]);


    return (
        <>
            <Sprite
                ref={fire1Ref}
                zIndex={2}
                alpha={0}
                x={calculateSize(2503)}//calculateSize(2263)
                y={calculateSize(1225)}//calculateSize(767)
                width={calculateSize(251)}
                height={calculateSize(262)}
                texture={croppedFire1}
            />
            <Sprite
                ref={fire2Ref}
                zIndex={3}
                alpha={0}
                x={calculateSize(2490)}//calculateSize(2263)
                y={calculateSize(1048)}//calculateSize(767)
                width={calculateSize(335)}
                height={calculateSize(470)}
                texture={croppedFire2}
            />
            <Sprite
                ref={fire3Ref}
                zIndex={3}
                alpha={0}
                x={calculateSize(2455)}//calculateSize(2263)
                y={calculateSize(800)}//calculateSize(767)
                width={calculateSize(405)}
                height={calculateSize(704)}
                texture={croppedFire3}

            />
        </>
    );
});

export default FireAnimation;