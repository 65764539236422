import {useEffect, useRef } from "react";

function useBubbleHover() {
    const ref = useRef<any>(null);

    useEffect(() => {
        function handleMouseEnter(e: MouseEvent) {
            if (ref.current) {
                const parentOffset = ref.current.getBoundingClientRect();

                const relX = e.clientX - parentOffset.left;
                const relY = e.clientY - parentOffset.top;

                const circle = ref.current.querySelector('.button__effect');
                circle.style.left = relX + 'px';
                circle.style.top = relY + 'px';

                circle.classList.remove("desplode-circle");
                circle.classList.add("explode-circle");
                /*                const parentOffset = ref.current.getBoundingClientRect();
                const relX = e.pageX - parentOffset.left - window.scrollX;
                const relY = e.pageY - parentOffset.top - window.scrollY;
                ref.current.querySelector('.button__effect').style.top = `${relY}px`;
                ref.current.querySelector('.button__effect').style.left = `${relX}px`;*/
            }
        }

        function handleMouseLeave(e: MouseEvent) {
            if (ref.current) {

                const parentOffset = ref.current.getBoundingClientRect();

                const relX = e.clientX - parentOffset.left;
                const relY = e.clientY - parentOffset.top;

                const circle = ref.current.querySelector('.button__effect');
                circle.style.left = relX + 'px';
                circle.style.top = relY + 'px';

                circle.classList.remove("explode-circle");
                circle.classList.add("desplode-circle");

               /* const parentOffset = ref.current.getBoundingClientRect();
                const relX = e.pageX - parentOffset.left - window.scrollX;
                const relY = e.pageY - parentOffset.top - window.scrollY;
                ref.current.querySelector('.button__effect').style.top = `${relY}px`;
                ref.current.querySelector('.button__effect').style.left = `${relX}px`;*/
            }
        }

        const element = ref.current;
        element?.addEventListener('mouseenter', handleMouseEnter);
        element?.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            element?.removeEventListener('mouseenter', handleMouseEnter);
            element?.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [ref]);

    return ref;
}

export default useBubbleHover;