export {}

export function loadImages(urls: string[],onImageLoad: (image: HTMLImageElement) => void): Promise<HTMLImageElement[]> {
    const promises: Promise<HTMLImageElement>[] = [];

    for (const url of urls) {
        const promise = new Promise<HTMLImageElement>((resolve, reject) => {
            const image = new Image();
            image.src = url;

            image.onload = () => {
                onImageLoad(image);
                resolve(image);
            };

            image.onerror = (error) => {
                reject(error);
            };
        });

        promises.push(promise);
    }

    return Promise.all(promises);
}


export function wrapWordsInDiv(text: any) {
    const words: string[] = text.split(' '); // Разбиваем текст на слова
    const wrappedWords: string = words.map((word: string,index:number) => {
        let text = "";

        if(index === 0){
            text += "<div class='strip-panel__line-row'>";
        }
        else if(word.includes("<br>") || word.includes("<br/>")){
            text += "</div><div class='strip-panel__line-row'>";
        }

        text +=`<div class="strip-panel__word"><div class="strip-panel__word-decor"></div>${word}</div>`;

        if(index === (words.length-1)){
            text +=  "</div>";
        }

        return text;
    }).join(' ');

    const parser = new DOMParser();
    const parsedText = parser.parseFromString(wrappedWords, 'text/html').body;
    return parsedText.innerHTML; // Возвращаем HTML с обёрнутыми словами
}


export function createSound(sound:string) {
    let audio = new Audio();
    audio.src = sound;
    audio.autoplay = true;
}