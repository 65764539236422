import {createSlice, PayloadAction} from "@reduxjs/toolkit";


interface checkState {
    checkAccommodation:boolean;
    checkClans:boolean;
    checkMeal:boolean;
    checkGetAround:boolean;
    checkGetThere:boolean;
    checkBurningMan:boolean;
    isMailSend:boolean;
    checkInfo:string[];

    isLoading: boolean;
    error: string;
    errorCode: string;
    successMessage: string;
    successMessageError: string;
}

const initialState: checkState = {
    checkAccommodation: false,
    checkClans:false,
    checkMeal:false,
    checkGetAround:false,
    checkBurningMan: false,
    checkGetThere:false,
    checkInfo:[],
    isMailSend:false,
    isLoading: false,
    error: '',
    errorCode: '',
    successMessage: '',
    successMessageError: ""
};


const checkSlice = createSlice({
    name:'checkSlice',
    initialState,
    reducers:{
        changeCheckAccommodation(state,action){
            state.checkAccommodation = action.payload;
        },
        changeCheckClans(state,action){
            state.checkClans = action.payload;
        },
        changeCheckMeals(state,action){
            state.checkMeal = action.payload;
        },
        changeCheckGetThere(state,action){
            state.checkGetThere = action.payload;
        },
        changeCheckGetAround(state,action){
            state.checkGetAround = action.payload;
        },
        changeMailSend(state,action){
            state.isMailSend = action.payload;
        },
        changeCheckBurningMan(state,action){
            state.checkBurningMan = action.payload;
        },
        changeCheckInfo(state,action){
            state.checkInfo = action.payload;
        },
    },
    extraReducers: (builder:any) => {

    },
});
export const {
    changeCheckAccommodation,
    changeCheckClans,
    changeCheckMeals,
    changeCheckGetThere,
    changeCheckGetAround,
    changeMailSend,
    changeCheckBurningMan,
    changeCheckInfo

} = checkSlice.actions;
export default checkSlice.reducer;