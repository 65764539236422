export const longFengCarCropRectangles: any[] = [
    { x: 0, y: 0, width: 875/2, height: 757/2 },
    { x: 875/2, y: 0, width: 875/2, height: 757/2 },
];

export const bikesCropRectangles: any[] = [
    { x: 0, y: 0, width: 226, height: 334 },
    { x: 226, y: 0, width: 226, height: 334 },
];


export const airportCropRectangles: any[] = [
    { x: 0, y: 0, width: 630, height: 666 },
    { x: 630, y: 0, width: 630, height: 666 },
];

export const bunnyCropRectangles: any[] = [
    { x: 0, y: 0, width: 434, height: 454 },
    { x: 434, y: 0, width: 434, height: 454 },
];

export const cakesCropRectangles: any[] = [
    { x: 0, y: 0, width: 342, height: 341 },
    { x: 342, y: 0, width: 342, height: 341 },
];

export const iceCropRectangles: any[] = [
    { x: 0, y: 0, width: 531, height: 612 },
    { x: 531, y: 0, width: 531, height: 612 },
];

export const mailCropRectangles: any[] = [
    { x: 0, y: 0, width: 234, height: 401 },
    { x: 251, y: 0, width: 234, height: 401 },
];

export const medicCropRectangles: any[] = [
    { x: 0, y: 0, width: 309, height: 273 },
    { x: 309, y: 0, width: 309, height: 273 },
];

export const repairCropRectangles: any[] = [
    { x: 0, y: 0, width: 319, height: 360 },
    { x: 317, y: 0, width: 318, height: 360 },
];

export const time3CropRectangles: any[] = [
    { x: 0, y: 0, width: 320, height: 461 },
    { x: 320, y: 0, width: 320, height: 461 },
];

export const time9CropRectangles: any[] = [
    { x: 0, y: 0, width: 312, height: 495 },
    { x: 312, y: 0, width: 312, height: 495 },
];

export const ebikesCropRectangles: any[] = [
    { x: 0, y: 0, width: 288, height: 288 },
    { x: 288, y: 0, width: 288, height: 288 },
];

export const mainCampCropRectangles: any[] = [
    { x: 0, y: 0, width: 709, height: 703 },
    { x: 709, y: 0, width: 709, height: 703 },
];

export const scrollCropRectangles: any[] = [
    { x: 0, y: 0, width: 586, height: 670 },
    { x: 586, y: 0, width: 586, height: 670 },
];

export const tempeCropRectangles: any[] = [
    { x: 0, y: 0, width: 786, height: 878 },
    { x: 786, y: 0, width: 786, height: 878 },
];
export const ringManCropRectangles: any[] = [
    { x: 0, y: 0, width: 511, height: 584 },
    { x: 511, y: 0, width: 511, height: 584 },
];

function scaleRectangles(rectangles:any) {
    return rectangles.map((rect:any) => ({
        x: rect.x / 2,
        y: rect.y / 2,
        width: rect.width / 2,
        height: rect.height / 2,
    }));
}

export const longFengCarCropRectanglesW2 = scaleRectangles(longFengCarCropRectangles);
export const bikesCropRectanglesW2 = scaleRectangles(bikesCropRectangles);
export const airportCropRectanglesW2 = scaleRectangles(airportCropRectangles);
export const bunnyCropRectanglesW2 = scaleRectangles(bunnyCropRectangles);
export const cakesCropRectanglesW2 = scaleRectangles(cakesCropRectangles);
export const iceCropRectanglesW2 = scaleRectangles(iceCropRectangles);
export const mailCropRectanglesW2 = scaleRectangles(mailCropRectangles);
export const medicCropRectanglesW2 = scaleRectangles(medicCropRectangles);
export const repairCropRectanglesW2 = scaleRectangles(repairCropRectangles);
export const time3CropRectanglesW2 = scaleRectangles(time3CropRectangles);
export const time9CropRectanglesW2 = scaleRectangles(time9CropRectangles);
export const ebikesCropRectanglesW2 = scaleRectangles(ebikesCropRectangles);
export const mainCampCropRectanglesW2 = scaleRectangles(mainCampCropRectangles);
export const scrollCropRectanglesW2 = scaleRectangles(scrollCropRectangles);
export const tempeCropRectanglesW2 = scaleRectangles(tempeCropRectangles);
export const ringManCropRectanglesW2 = scaleRectangles(ringManCropRectangles);
