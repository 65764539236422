import './Progress.scss';
import React, {FC} from 'react';

interface IProgress{
    percent?:number;
}

const Progress:FC<IProgress> = ({percent}) => {
    return (
        <div className={'progress'}>
            <span style={{width: `${percent}%`}}></span>
        </div>
    );
};

export default Progress;