import React, {FC} from 'react';
import GlobalMapContainer from "../components/containers/GlobalMapContainer";
import WelcomeModal from "../components/molecules/WelcomeModal/WelcomeModal";
import VideoModal from "../components/molecules/VideoModal/VideoModal";

const IntroPage:FC = () => {
    return (
        <>
         {/*   <VideoModal/>
            <WelcomeModal/>*/}
      {/*      <WelcomeModal/>*/}
            <GlobalMapContainer/>
        </>
    );
};

export default IntroPage;