import {FC, useEffect, useState} from 'react';

import ModalAccommodation from '../molecules/Modals/ModalAccommodation';
import ModalClans from '../molecules/Modals/ModalClans';
import ModalGetAround from '../molecules/Modals/ModalGetAround';
import ModalBurningMan from "../molecules/Modals/ModalBurningMan";
import ModalMeal from "../molecules/Modals/ModalMeal";
import ModalGetThere from "../molecules/Modals/ModalGetThere";
import ModalKey from '../molecules/ModalKey/ModalKey';
import {useDispatchEx, useSelectorEx} from '../../hooks/redux';
import {changeModalFirstKey, changeModalPurchase, changeModalSecondKey} from "../../redux/slices/modalSlice/modalSlice";
import ModalInformation from '../molecules/Modals/ModalInformation';
import ModalPrinciples from '../molecules/Modals/ModalPrinciples';
import ModalArtCar from "../molecules/Modals/ModalArtCar";
import ModalFinal from '../molecules/ModalFinal/ModalFinal';
import {changeIsCloseSecondModal} from "../../redux/slices/appSlice";
import ModalLoves from "../molecules/ModalLoves/ModalLoves";
import ModalTutorial from "../molecules/ModalTutorial/ModalTutorial";
import ModalDates from "../molecules/ModalDates/ModalDates";
import WarningIcon from "../icons/WarningIcon";


const Modals:FC = () => {
    const {modalFirstKey,modalSecondKey,modalPrinciples,modalNight,modalPurchase} = useSelectorEx(state=>state.modals);
    const {currentKeys,isCloseSecondModal} = useSelectorEx(state=>state.app);
    const [end, setEnd] = useState(false);
    const dispatch = useDispatchEx();

    useEffect(() => {
        if(currentKeys === 3  ){
            setTimeout(()=>{
                setEnd(true)
            },2000)
        }
    }, [currentKeys,isCloseSecondModal]);
    useEffect(() => {
        if(modalNight){
            setEnd(false)
        }
    }, [modalNight]);
    return (
        <>
{/*            <ModalClans/>
            <ModalAccommodation/>
            <ModalGetAround/>
            <ModalGetThere/>

            <ModalMeal/>*/}
            <ModalBurningMan/>
      {/*     <ModalKey
                icon={<WarningIcon/>}
                buttonText="Continue"
                title={"Choices made in-game do not translate to real-life purchases."}
                active={modalPurchase}
                onClose={()=>dispatch(changeModalPurchase(false))}
            >
                Please refer to the Playa Resource Guide in your email with links to where to purchase gear and make your reservations.
            </ModalKey>*/}

        {/*    <ModalKey
                title={"You’ve found the first key!"}
                active={modalFirstKey}
                onClose={()=>dispatch(changeModalFirstKey(false))}
            >
                Explore the map to learn more about Burning Man. After completing your estimate, objects that are still glowing are interactive — play around and discover all of the keys to unlock the Burn!
            </ModalKey>*/}

        {/*    <ModalKey
                title={"You’ve found a second key!"}
                active={modalSecondKey}
                onClose={()=> {
                        dispatch(changeIsCloseSecondModal(true))
                        dispatch(changeModalSecondKey(false))
                    }
                }
            >
                You’re doing great—just one more key to discover. Can you find it?
            </ModalKey>*/}

            <ModalInformation/>
            <ModalPrinciples/>
            <ModalArtCar/>
            <ModalFinal
                active={end}
            />
           {/* <ModalTutorial/>*/}
      {/*      <ModalDates/>*/}
        </>
    );
};

export default Modals;