import {Rectangle, Texture} from "pixi.js";
import {useMemo} from "react";

interface CropRectangle {
    x: number;
    y: number;
    width: number;
    height: number;
}

export const useCroppedTextures = (imageSource: string, cropRectangles: CropRectangle[]): Texture[] => {
    return useMemo(() => {
        const originalTexture = Texture.from(imageSource);
        return cropRectangles.map(({ x, y, width, height }) => {
            const cropRectangle = new Rectangle(x, y, width, height);
            return new Texture(originalTexture.baseTexture, cropRectangle);
        });
    }, [imageSource, cropRectangles]);
};

export default useCroppedTextures;